<!-- Real file input, hidden and replaced by a display field -->
<input
  type="file"
  class="file-input"
  [accept]="requiredFileType"
  (change)="onFileSelected($event)"
  #fileUpload
  [disabled]="isDisabled"
  [attr.multiple]="multiple ? true : null"
/>

<!-- Display field, triggers the real file input and only serves as a display -->
<mat-form-field [appearance]="fieldAppearance" class="field-stretch">
  <mat-label>{{ labelKey | translate }} *</mat-label>
  <input
    matInput
    [formControl]="displayCtrl"
    autocomplete="off"
    readonly
    [placeholder]="'common.validation.no-file-uploaded' | translate"
    (click)="onOpenFileUpload($event, fileUpload)"
  />
  <button
    mat-icon-button
    matSuffix
    color="primary"
    class="upload-btn"
    (click)="onOpenFileUpload($event, fileUpload)"
  >
    <mat-icon>attach_file</mat-icon>
  </button>
</mat-form-field>

<!-- <div class="progress" *ngIf="showProgress">
  <mat-progress-bar
    class="progress-bar"
    mode="determinate"
    [value]="uploadProgress"
    *ngIf="uploadProgress"
  >
  </mat-progress-bar>
</div> -->
