import { Injectable } from '@angular/core';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { BaseChartService } from '@common-modules/wlm-charts/core/base-chart/base-chart.service';
import { GChartAxis } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-axis';
import { GChartDataZoom } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-data-zoom';
import { GChartLegend } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-legend';
import { GChartLegendItem } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-legend-item';
import { GChartTooltip } from '@common-modules/wlm-charts/core/models/generic-chart-settings/g-chart-tooltip';
import { GSchematicChartSerie } from '@common-modules/wlm-charts/core/models/schematics/g-schematic-chart-serie';
import { GenericSchematicChartSettings } from '@common-modules/wlm-charts/core/models/schematics/generic-schematic-chart-settings';
import {
  Schematic,
  SchematicCategory,
  SchematicLink,
  SchematicNode,
  SchematicNodeProcessed,
} from '@common-modules/wlm-charts/core/models/schematics/schematic';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseSchematicChartService extends BaseChartService<GenericSchematicChartSettings> {
  private readonly _pathPrefix = 'path://';

  mapDataToGenericSettings(schematic: Schematic): Observable<GenericSchematicChartSettings> {
    const categoriesInOrder = schematic.categories;

    const legend = new GChartLegend({
      data: categoriesInOrder.map((category: SchematicCategory) => {
        const defaultLegendItem: GChartLegendItem = {
          name: category.name,
        };
        const extended = this.extendLegendItem(category, defaultLegendItem);
        return extended;
      }),
    });

    const legendIndexById = new Map<string, number>();
    const categoryIdByIndex = new Map<number, string>();

    const categoriesById = new Map<string, SchematicCategory>();
    categoriesInOrder.forEach((category, index) => {
      legendIndexById.set(String(category.id), index);
      categoryIdByIndex.set(index, category.id);
      categoriesById.set(String(category.id), category);
    });

    const processedNodes = schematic.nodes.map((node) => {
      const category = categoriesById.get(node.category);
      const processedNode = this.mapNodeToProcessed(node, legendIndexById);
      return this.extendNode(category, processedNode);
    });

    const processedLinks = schematic.links.map((link) => {
      const source = processedNodes.find((node) => node.id === link.source);
      const target = processedNodes.find((node) => node.id === link.target);
      return this.extendLink(link, source, target);
    });

    const defaultSerie = new GSchematicChartSerie({
      data: processedNodes,
      links: processedLinks,
      categories: categoriesInOrder,
      coordinateSystem: 'cartesian2d',
      xAxisIndex: 0,
      yAxisIndex: 0,
      symbolKeepAspect: true,
    });

    // Allow child services to extend the series.
    const mainSerie$ = this.extendSerie(defaultSerie);

    const tooltip = this.updateTooltipWithCategories(
      new GChartTooltip({
        trigger: 'item',
        borderColor: '#fff',
      }),
      categoriesInOrder
    );

    return mainSerie$.pipe(
      map((serie) => {
        const chartSettings = new GenericSchematicChartSettings({
          grid: {
            y: '0',
            y2: '0',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
          series: [serie],
          legend,
          tooltip,
          xAxes: [
            new GChartAxis({
              id: 'mainX',
              type: 'value',
              min: -180,
              max: 180,
            }),
          ],
          yAxes: [
            new GChartAxis({
              id: 'mainY',
              type: 'value',
              min: -90,
              max: 90,
            }),
          ],
          dataZoom: this.buildDataZooms(),
        });
        return chartSettings;
      })
    );
  }

  updateTooltipWithCategories(currentTooltip: any, categories: SchematicCategory[]): any {
    const legendIndexById = new Map<string, number>();
    const categoryIdByIndex = new Map<number, string>();

    const categoriesById = new Map<string, SchematicCategory>();
    categories.forEach((category, index) => {
      legendIndexById.set(String(category.id), index);
      categoryIdByIndex.set(index, category.id);
      categoriesById.set(String(category.id), category);
    });

    const tooltip = new GChartTooltip({
      formatter: (params) => {
        if (!params?.data) {
          return null;
        }
        const categoryIndex = (params.data as SchematicNodeProcessed).category;
        const categoryId = categoryIdByIndex.get(categoryIndex);
        const category = categoriesById.get(categoryId);

        if (params.dataType === 'node') {
          return this.buildNodeTooltipFormatter(category, params);
        } else if (params.dataType === 'edge') {
          return this.buildLinkTooltipFormatter(category, params);
        }
        return null;
      },
    });

    return { ...currentTooltip, formatter: tooltip.formatter };
  }

  /**
   * Allows to extend a serie based on its category.
   */
  abstract extendSerie(serie: GSchematicChartSerie): Observable<GSchematicChartSerie>;

  abstract extendLegendItem(
    category: SchematicCategory,
    legendItem: GChartLegendItem
  ): GChartLegendItem;

  /**
   * Allows to extend a node based on its category.
   * As the number of nodes can be very large, we should not attempt to resolve any observables
   * here, but when fetching the schematic.
   */
  abstract extendNode(
    category: SchematicCategory,
    node: SchematicNodeProcessed
  ): SchematicNodeProcessed;

  /**
   * Allows to extend a node based on its category.
   * As the number of nodes can be very large, we should not attempt to resolve any observables
   * here, but when fetching the schematic.
   */
  abstract extendLink(
    link: SchematicLink,
    sourceNode: SchematicNodeProcessed,
    targetNode: SchematicNodeProcessed
  ): SchematicLink;

  protected getPathFromSvg(svgText: string): string {
    if (!svgText) {
      return svgText;
    }

    const path = globalUtilsHelper.getPathFromSvg(svgText);
    return path.startsWith(this._pathPrefix) ? path : `${this._pathPrefix}${path}`;
  }

  /**
   * This method cannot return an observable, because it is directly passed to the charts tooltip.
   * All the async dependencies should be loaded before this method can execute, in the getData method.
   */
  abstract buildNodeTooltipFormatter(category: SchematicCategory, params): string;
  abstract buildLinkTooltipFormatter(category: SchematicCategory, params): string;

  getXCoordinate = (node: SchematicNodeProcessed): number => {
    return node?.value[0];
  };

  getYCoordinate = (node: SchematicNodeProcessed): number => {
    return node?.value[1];
  };

  protected getTooltipRow = (header: string, value: string): string =>
    `<strong>${header}:</strong> ${value}`;

  mapNodeToProcessed(
    node: SchematicNode,
    legendIndexById: Map<string, number>
  ): SchematicNodeProcessed {
    const processedNode = { ...node } as any as SchematicNodeProcessed;

    processedNode.value = [node.x, node.y];
    delete (processedNode as any).x;
    delete (processedNode as any).y;

    if (node.category) {
      processedNode.category = legendIndexById.get(node.category);
    }

    return processedNode;
  }

  buildDataZooms(): GChartDataZoom[] {
    const dataZooms = [
      new GChartDataZoom({
        id: 'insideX',
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'none',
      }),
      new GChartDataZoom({
        id: 'insideY',
        type: 'inside',
        yAxisIndex: 0,
        filterMode: 'none',
      }),
    ];
    return dataZooms;
  }

  mapNodeToProcessedFromSerie(
    node: SchematicNode,
    schematicCategories: SchematicCategory[]
  ): SchematicNodeProcessed {
    const legendIndexById = new Map<string, number>();

    schematicCategories.forEach((category, index) => {
      legendIndexById.set(String(category.id), index);
    });

    return this.mapNodeToProcessed(node, legendIndexById);
  }
}
