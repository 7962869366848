// prettier-ignore
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { AuthenticationService } from '@common-modules/shared/auth/services/authentication.service';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { IExportPdfComponent } from '@common-modules/shared/exports/models/export-pdf-component';
import {
  ExportPdfDocument,
  PdfExportItem,
} from '@common-modules/shared/exports/models/export-pdf-document';
import { GenericExportService } from '@common-modules/shared/exports/service/generic-export.service';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { UtilsHelperService } from '@common-modules/shared/helpers/utils-helper.service';
import { IconLoaderService } from '@common-modules/shared/icon-loader.service';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { AlgorithmAttributesIds } from '@common-modules/shared/model/algorithm/algorithm-attributes-ids';
import { WlmDialogSettings } from '@common-modules/shared/model/dialog/wlm-dialog-setting';
import { IGisCollectionDto } from '@common-modules/shared/model/gis/gis-collection.dto';
import { GisElementTypesEnum } from '@common-modules/shared/model/gis/gis-element-types.enum';
import { IGisElementDto } from '@common-modules/shared/model/gis/gis-element.dto';
import { IGisLayerDto } from '@common-modules/shared/model/gis/gis-layer.dto';
import { SvgIcon } from '@common-modules/shared/model/shared/svg-icon';
import { RightPanelService } from '@common-modules/shared/navigation/right-panel.service';
import { SubscriptionManager } from '@common-modules/shared/observables/subscription-manager';
import { DynamicRenderizerComponentService } from '@common-modules/shared/services/dynamic-renderizer-component.service';
import { ThemeService } from '@common-modules/shared/theme/theme.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as geo from 'geojson';
import { asEnumerable } from 'linq-es2015';
import mapboxgl, { AnyLayer, GeoJSONSource, LngLatLike, MapDataEvent, Point } from 'mapbox-gl';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  combineLatest,
  forkJoin,
  of,
  timer,
} from 'rxjs';
import { debounce, debounceTime, map, take } from 'rxjs/operators';
import { KpiTitlePipe } from '../../shared/pipes/kpi-title-pipe';
import { BaseMap } from '../base-map';
import { mapDefaults } from '../map-defaults';
import { MapSettings } from '../map-filter/models/map-filter-settings';
import { MapHelperService } from '../map-helper.service';
import { MapLayerSourcesService, VectorSource, VectorSources } from '../map-layer-sources.service';
import { MapParameters } from '../map-parameters';
import { MapThematicKpi } from '../map-thematic/models/map-thematic-kpi';
import { KpiSettingValue } from '../map-thematic/models/map-thematic-kpi-value';
import { MapThematicTooltipInfo } from '../map-thematic/models/map-thematic-tooltip-info';
import { MapThemes, mapThemesMapping } from '../map-themes-mapping';
import { MapTooltipComponent } from '../map-tooltip/map-tooltip.component';
import { MapTooltip } from '../map-tooltip/models/map-tooltip';
import { MapTooltipProperty } from '../map-tooltip/models/map-tooltip-property';
import { MapBounds } from '../models/map-bounds';
import { MapFeature } from '../models/map-feature';
import { MapMarker } from '../models/map-marker';

const COMPONENT_SELECTOR = 'wlm-base-map';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './base-map.component.html',
  styleUrls: ['./base-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseMapComponent extends BaseMap implements OnInit, IExportPdfComponent {
  @ViewChild('mapContainer') set mapContainer(value: ElementRef) {
    this._mapContainer = value;
    if (value && !this.hasBeenInitialized) {
      this.initializeMap();
      this.hasBeenInitialized = true;
    }
  }

  @Input() initializeMap$ = new Subject<void>();
  @Input() displayMessage$: BehaviorSubject<WlmDialogSettings>;
  @Input() toggleMapOpacity$ = new Subject<void>();

  private readonly _navigationZoom = 13.99;
  private readonly _customerPropertyIdAttribute = 'a-581';
  private readonly _emptyFeatures: geo.FeatureCollection<geo.Geometry> = {
    type: 'FeatureCollection',
    features: [],
  };

  private _baseSource: mapboxgl.Sources;
  private _vectorSources: VectorSources;
  private _featuresSource: GeoJSONSource;

  private _heLayerName = 'he';
  private readonly _geojsonSourceName = 'geojson-source';

  private _marker: MapMarker;

  private _popup: mapboxgl.Popup;

  private _receivedMapParameters: MapParameters;

  private _mapParameters: MapParameters;
  public get mapParameters(): MapParameters {
    return this._mapParameters;
  }

  @Input()
  public set mapParameters(value: MapParameters) {
    if (!value) {
      return;
    }

    value = this._mapHelperService.validateLayersParameters(value, this.zoneLayers);

    if (!this.map) {
      this._mapHelperService.setPendingMapParameters(value);
      return;
    }

    this.initializeMapParametersWithValue(value);

    this._cd.detectChanges();
  }

  @Output() moveEndEvent = new EventEmitter<any>();

  @Output() mapLoaded = new EventEmitter<void>();

  @Output() coordinatesClicked = new EventEmitter<LngLatLike>();

  private _mapContainer: ElementRef;
  get mapContainer(): ElementRef {
    return this._mapContainer;
  }

  private _allLayers: IGisLayerDto[];
  get allLayers(): IGisLayerDto[] {
    return this._allLayers;
  }
  set allLayers(value: IGisLayerDto[]) {
    this._allLayers = value;
    this._allLayers$.next(this.allLayers);
  }
  private _allLayers$ = new ReplaySubject<IGisLayerDto[]>(1);

  private _persistencyLoaded$ = new Subject<void>();
  private _paintBaseLayerWithMaxOpacity: any = {
    'raster-opacity': ['interpolate', ['linear'], ['zoom'], 0, 0, 16, 0, 18, 0, 19, 0, 22, 0],
  };

  hasBeenInitialized = false;
  displayLayersFilter = false;
  layersFilterDisabled = true;
  mapPanelInfo: string;
  features: mapboxgl.MapboxGeoJSONFeature[] = [];
  layers: Map<number, IGisLayerDto>;

  thematicsFilterDisabled = true;
  thematicKpis: MapThematicKpi[];
  thematicLayers: mapboxgl.Layer[];
  layerIcons: Map<number, SvgIcon> = new Map<number, SvgIcon>();
  selectedKpiInfo: MapThematicKpi;
  selectedMapThematicTooltipInfo: MapThematicTooltipInfo;
  currentZoom: number;

  private mapOpacityEnabled = false;
  private _outOfRangeMapColorValue: string;
  private _mapStyleLoaded = false;
  private _mapTheme: MapThemes = MapThemes.Light;
  private _themeChangeSubs: Subscription;
  private readonly _genericFilename = 'map';
  private readonly _defaultPageWidth = 270;
  private readonly _defaultPageHeight = 170;
  private readonly _mergedZonesPatternSource = 'merged-zones-pattern';
  private readonly _featureSizeLimit = 50000;

  private _isMapReady$: ReplaySubject<void>;
  private _subsManager: SubscriptionManager;

  get componentName() {
    return 'BaseMapComponent';
  }

  get zoneLayers(): IGisLayerDto[] {
    return this._allLayers?.filter((layer) => !!layer.isZone);
  }

  get visibleVectorSources(): VectorSources {
    const visibleVectorSources = this.getVisibleVectorSources();
    const hashedVectorSources = {};
    visibleVectorSources.forEach((x) => (hashedVectorSources[x[0]] = x[1]));
    return hashedVectorSources;
  }

  get visibleZoneLayersIds(): number[] {
    const layersIds = Object.values(this.visibleVectorSources)
      .filter((v) => !!v.isZone)
      .map((v) => v.gisLayerId);

    return layersIds;
  }

  get visibleLayers(): mapboxgl.Layer[] {
    const layers: mapboxgl.Layer[] = [];

    // The sorting of sources makes sense when computing its layers, but not in the this.visibleVectorSources getter,
    // as it is an object and does not preserve any ordering.
    const orderedVectorSources = asEnumerable(Object.values(this.visibleVectorSources))
      .OrderByDescending((x) => x.isZone)
      .ThenByDescending((x) => x.isLine)
      .ThenByDescending((x) => x.isMergedZone)
      .ThenBy((x) => x.gisOrder)
      .ToArray();

    orderedVectorSources.forEach((source) => {
      const layer = this.getLayer(source);
      const lArray = layer as mapboxgl.Layer[];
      const lSingle = layer as mapboxgl.Layer;

      if (lArray?.length > 1) {
        layers.push(...lArray);
      } else {
        layers.push(lSingle);
      }
    });

    const heLayer = this._sourcesService.getHeSelectedLayer(this._heLayerName);
    layers.push(heLayer);

    return layers;
  }

  get style(): mapboxgl.Style {
    const paintBaseLayerDefinition = this.mapOpacityEnabled
      ? this._paintBaseLayerWithMaxOpacity
      : null;
    const baseLayer = this._sourcesService.getBaseLayer();
    let layers: mapboxgl.Layer[] = [baseLayer];
    layers = layers.concat(this.thematicLayers.concat(this.visibleLayers));

    const sources: mapboxgl.Sources = { ...this._baseSource, ...this._vectorSources };

    if (this._featuresSource) {
      this.addGeoJsonSource(sources);

      const featuresLayer = this._sourcesService.getFeaturesLayer(
        this._featuresType,
        this._geojsonSourceName,
        paintBaseLayerDefinition
      );

      layers.push(featuresLayer);
    }

    const style: mapboxgl.Style = {
      version: 8,
      sources: sources,
      layers: layers as AnyLayer[],
      center: this.center,
      glyphs: '../../../assets/fonts/glyphs.pbf?{fontstack}/{range}',
    };

    let heSource;
    let currentStyle;

    try {
      currentStyle = this.map?.getStyle();
    } catch {}

    if (this._mapStyleLoaded && currentStyle) {
      heSource = currentStyle.sources[this._heLayerName];
    } else {
      heSource = {
        type: 'geojson',
        data: this._emptyFeatures,
        buffer: 10,
        tolerance: 1.25,
      };
    }

    style.sources[this._heLayerName] = heSource;

    return style;
  }

  constructor(
    private readonly _sourcesService: MapLayerSourcesService,
    private readonly _mapHelperService: MapHelperService,
    private readonly _rightPanelService: RightPanelService,
    private readonly _dynamicRenderizerService: DynamicRenderizerComponentService,
    private readonly _cd: ChangeDetectorRef,
    private readonly _objectHelper: ObjectHelperService,
    private readonly _utilsHelperService: UtilsHelperService,
    private readonly _localizationHelper: LocalizationHelperService,
    private readonly _dialogService: DialogService,
    private readonly _genericExportService: GenericExportService,
    private readonly _mapLayerSourcesService: MapLayerSourcesService,
    private readonly _themeService: ThemeService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _iconLoaderService: IconLoaderService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.initializeMap$) {
      this.initializeMap$.pipe(untilDestroyed(this)).subscribe(() => this.resetMap());
    }

    if (this.toggleMapOpacity$) {
      this.toggleMapOpacity$.pipe(untilDestroyed(this)).subscribe(() => this.toggleMapOpacity());
    }

    this.displayMessage$
      ?.pipe(untilDestroyed(this))
      .subscribe((dialogSettings) => this.displayMessage(dialogSettings));

    const tileSetsTypes = this._mapLayerSourcesService.getTileSetTypes();
    this.baseType = tileSetsTypes.length ? tileSetsTypes[0] : null;

    this.getOutOfRangeMapColor();
  }

  toggleMapOpacity(): void {
    this.mapOpacityEnabled = !this.mapOpacityEnabled;

    this.map?.setStyle(this.style);
  }

  resetMap() {
    this.map = null;
    this.initializeMap();

    let newParameters = this._objectHelper.clone(this.mapParameters);
    const persistedMapsettings = this._mapHelperService.getPersistedData(
      this.mapParameters.settingKey,
      null,
      true
    );

    if (persistedMapsettings) {
      const finalSettings = this.initializeMapParameters(persistedMapsettings, this.mapParameters);
      newParameters = finalSettings;
    }

    newParameters.loadFromPersistency = persistedMapsettings === null;

    this.mapParameters = newParameters;
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  initializeMapParameters(persistedSetting: MapSettings, receivedSetting: MapParameters) {
    const mapParameters = MapParameters.getparameter({
      visibleLayersIds: persistedSetting
        ? persistedSetting.visibleLayersIds
        : receivedSetting.visibleLayersIds,
      leakYears: persistedSetting ? persistedSetting.leakYears : receivedSetting.leakYears,
      visibleThematicsIds: persistedSetting
        ? persistedSetting.visibleThematicsIds
        : receivedSetting.visibleThematicsIds,
      center: persistedSetting ? persistedSetting.center : receivedSetting.center,
      zoom: persistedSetting ? persistedSetting.zoom : receivedSetting.zoom,
      showFilters: receivedSetting.showFilters,
      settingArea: receivedSetting.settingArea,
      settingKey: receivedSetting.settingKey,
      navigatedElement: receivedSetting.navigatedElement,
      hierarchyElements: receivedSetting.hierarchyElements,
      networkElements: receivedSetting.networkElements,
    });

    return mapParameters;
  }

  getLayer(vectorSource: VectorSource): mapboxgl.Layer | mapboxgl.Layer[] {
    if (vectorSource.isZone) {
      return this._sourcesService.getZoneLayer(vectorSource);
    }

    if (vectorSource.isLine) {
      return this._sourcesService.getLineLayer(vectorSource);
    }

    const isLeakLayer = this._sourcesService.isLeakLayer(vectorSource.gisLayerId);
    const isActivityLayer = this._sourcesService.isActivityLayer(vectorSource.gisLayerId);
    if (!isLeakLayer && !isActivityLayer && vectorSource.iconPath) {
      return this._sourcesService.getSymbolLayer(vectorSource);
    }

    return this._sourcesService.getPointLayer(vectorSource);
  }

  getThematicLayers(): mapboxgl.Layer[] {
    const entries = Object.entries<VectorSource>(this._vectorSources);
    const vectorSources = entries.filter((vs) =>
      this._mapHelperService.isThematicZone(vs[1].gisLayerId)
    );

    const kpiValues = this.thematicKpis.reduce((accumulator, array) => {
      return [...accumulator, ...array.values];
    }, [] as KpiSettingValue[]);

    return this._sourcesService.getThematicLayers(
      vectorSources,
      kpiValues,
      this._outOfRangeMapColorValue
    );
  }

  onClickLayersFilter() {
    this.displayLayersFilter = !this.displayLayersFilter;
  }

  onLoadMapFilter() {
    this.layersFilterDisabled = false;
  }

  onSearchSelection(searchElement: {
    value: IGisElementDto | [number, number];
    coordinates: boolean;
  }) {
    if (!this.map || !searchElement) {
      return;
    }

    if (searchElement.coordinates) {
      return this.setMarker(searchElement.value as [number, number]);
    }

    let navigated = searchElement.value as IGisElementDto;
    if (!navigated.gisElementType) {
      navigated.gisElementType = GisElementTypesEnum.NetworkElement;
    }
    this.navigatedElement = navigated;
  }

  layersUpdated() {
    this.cleanThematicLayers();
    this.map.setStyle(this.style);
    this.setPopup();
  }

  setPopup() {
    if (!this._popup) {
      this._popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: false,
        maxWidth: 'none',
        className: 'map-popup',
      });
      this.setClickEventOnMap();
    }

    this._popup.remove();
  }

  private setClickEventOnMap() {
    this.map.on('click', this.onClick);
  }

  resize(eventData?: any): void {
    this.map?.resize(eventData);
  }

  getBounds() {
    const bounds: any = this.map.getBounds();
    const data = [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat];
    return data;
  }

  private waitUntilMapReady<T>(emitter$: EventEmitter<T>, callbackFn: (data: T) => void): void {
    this._subsManager.addSub = emitter$.pipe(untilDestroyed(this)).subscribe((data) => {
      this._isMapReady$
        .asObservable()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          callbackFn(data);
        });
    });
  }

  initializeMap(): void {
    this._subsManager?.unsubscribe();
    this._subsManager = new SubscriptionManager();
    this._isMapReady$ = new ReplaySubject(1);

    this.waitUntilMapReady(this.boundariesChange, this.onBoundariesChange);

    this._globals.getGisLayers().subscribe((x) => {
      this.allLayers = x;
      this.layers = asEnumerable(x).ToDictionary(
        (y) => y.gisLayerId,
        (y) => y
      );

      this._cd.detectChanges();
    });

    this.showFilterChange.pipe(untilDestroyed(this)).subscribe((x) => {
      this._cd.detectChanges();
    });

    this.baseTypeChange.pipe(untilDestroyed(this)).subscribe((x) => {
      this.refreshMapStyle();
    });

    this.waitUntilMapReady(this.zoomEnabledChange, this.onZoomEnabledChange);

    this.waitUntilMapReady(this.panningEnabledChange, this.onPanningEnabledChange);

    this.waitUntilMapReady(this.heCollectionChange, this.onHeCollectionChange);

    this.waitUntilMapReady(this.neCollectionChange, this.onNeCollectionChange);

    this.waitUntilMapReady(this.visibleLayersIdsChange, this.onVisibleLayersIdsChange);

    this.waitUntilMapReady(this.visibleThematicsIdsChange, this.onVisibleThematicsIdsChange);

    this.waitUntilMapReady(this.leakYearsChange, this.onLeakYearsChange);

    this.waitUntilMapReady(this.geojsonFeaturesChange, this.onGeojsonFeaturesChange);

    this.navigatedElementChange.pipe(untilDestroyed(this)).subscribe((x) => {
      if (!x && this._marker) {
        this._marker.remove();
      } else {
        this.applyNavigation();
      }
    });

    this._persistencyLoaded$.pipe(untilDestroyed(this)).subscribe(() => {
      this.map.setZoom(this.zoom);

      if (!this.navigatedElement) {
        this.map.setCenter({ lat: this.center[0], lng: this.center[1] });
      }
    });

    this.persistFilters$
      .pipe(debounceTime(300), untilDestroyed(this))
      .subscribe(() => this.notifyMapSettingsChanges());

    this._mapTheme = this.getCurrentMapTheme();
    //const layerFeatures = this._sourcesService.getSourceFeatures(this.geojsonFeatures);

    const baseLayer$ = this._sourcesService.getBaseSource(this._mapTheme, this.baseType);
    const layers$ = this._sourcesService.getLayers();
    const leakYears$ = this._sourcesService.getFiltrableGisLeakYears();
    const thematicKpis$ = this._sourcesService.getThematicKpiSettings();
    const layerSettings$ = this._sourcesService.getMapLayerSettings();

    combineLatest([baseLayer$, layers$, leakYears$, thematicKpis$, layerSettings$])
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ([baseLayer, layers, leakYears, thematicKpis, _]) => {
          if (this.map) {
            this._isMapReady$.next();
            this._isMapReady$.complete();
            return;
          }

          this._baseSource = baseLayer;
          this._vectorSources = layers;
          //this._featuresSource = layerFeatures;
          this._mapHelperService.setFiltrableGisLeakYears(leakYears);

          this.thematicKpis = thematicKpis;
          this.thematicLayers = this.getThematicLayers();

          this.map = new mapboxgl.Map({
            container: this.mapContainer.nativeElement,
            localIdeographFontFamily: 'Roboto, sans-serif',
            preserveDrawingBuffer: true, // this is necessary to export map to PDF,
            transformRequest: this.interceptAddToken,
          });

          const navCtrl = new mapboxgl.NavigationControl({
            showCompass: false,
          });
          this.map.addControl(navCtrl, 'bottom-right');

          this.setPopup();
          this.setClickEventOnMap();

          this.map.on('move', this.onMove);
          this.map.on('styledata', this.onStyledata);

          this.map.on('zoomend', () => {
            this.currentZoom = this.map?.getZoom();
            this._cd.detectChanges();
          });

          const pendingParameters = this._mapHelperService.getPendingMapParameters();
          if (pendingParameters) {
            this.mapParameters = pendingParameters;
            this._mapHelperService.setPendingMapParameters(null);
          }

          //////////// DEBOUNCE APPROACH
          // comment in this and the console.log to trace debounce behaviour in the console
          // let i = 0;
          this._rightPanelService
            .resizedObservable()
            .pipe(
              debounce(() => timer(100)),
              untilDestroyed(this)
            ) // set 100 ms to whatever is needed - it is more easy to see debounce working with hight values
            .subscribe(() => {
              this.map.resize();
              // console.log('debouncing ' + i++);
            });
          setTimeout(() => this.map.resize());

          // Load icons for Symbol Layers
          this.loadIconsForSymbolLayers(layers);
          this.buildDefaultIcons();

          this.map.setStyle(this.style);
          this.map.setZoom(this.zoom);
          this.map.setCenter({ lat: this.center[0], lng: this.center[1] });

          if (this.showPanel) {
            this.map.on('click', (e) => {
              const margin = 5;

              const p1: Point = new Point(e.point.x - margin, e.point.y - margin);
              const p2: Point = new Point(e.point.x + margin, e.point.y + margin);

              const features = this.map.queryRenderedFeatures([p1, p2]);
              this.features = features;

              const filter = features.reduce(
                (memo, feature) => {
                  memo.push(feature.properties.id);
                  return memo;
                },
                ['in', 'id']
              );

              const selectedLayers = this.map
                .getStyle()
                .layers.filter((x) => x.id.endsWith('selected'));

              selectedLayers.forEach((x) => {
                this.map.setFilter(x.id, filter);
              });
            });
          }

          this.map.on('load', () => {
            this.map.resize();
          });

          this.map.on('moveend', (event) => {
            this.moveEndEvent.next(event);
          });

          this.addMergedZonesBgImage();

          this.mapLoaded.emit();
          this._isMapReady$.next();
          this._isMapReady$.complete();

          if (this.navigatedElement) {
            this.applyNavigation();
          }

          this.listenThemeChanges();

          this._cd.detectChanges();
        },
        error: () => {
          this._isMapReady$.next();
          this._isMapReady$.complete();
        },
      });
  }

  private onBoundariesChange = (bounds): void => {
    this.map?.fitBounds(bounds, { animate: false, maxZoom: this._navigationZoom });
  };

  private onZoomEnabledChange = (zoom): void => {
    if (!this.map) {
      return;
    }

    if (zoom) {
      this.map.doubleClickZoom.enable();
      this.map.scrollZoom.enable();
    } else {
      this.map.doubleClickZoom.disable();
      this.map.scrollZoom.disable();
    }
  };

  private onPanningEnabledChange = (panning): void => {
    if (!this.map) {
      return;
    }

    if (panning) {
      this.map.dragPan.enable();
      this.map.dragRotate.enable();
      this.map.touchZoomRotate.enable();
    } else {
      this.map.dragPan.disable();
      this.map.dragRotate.disable();
      this.map.touchZoomRotate.disable();
    }
  };

  private onHeCollectionChange = (x): void => {
    if (!this.map) {
      return;
    }

    const layerSource = this.map.getSource(this._heLayerName) as GeoJSONSource;
    const layer = this.map.getLayer(this._heLayerName);

    if (!layerSource || !layer) {
      return;
    }

    const features = this.getFeatures(x);

    if (features) {
      layerSource.setData(features);
      this.map.setLayoutProperty(this._heLayerName, 'visibility', 'visible');

      if (features['features']?.length === 0) {
        this.displayMessage(
          new WlmDialogSettings({
            translateKey: `${AppModules.Map}.common.messages.missing-gis-info`,
            icon: 'warning',
          })
        );
      }
    } else {
      layerSource.setData(this._emptyFeatures);
      this.map.setLayoutProperty(this._heLayerName, 'visibility', 'none');
    }
  };

  private onNeCollectionChange = (x): void => {
    if (!this.map) {
      return;
    }

    const gisElement = x?.features[0];

    if (!gisElement) {
      this._marker?.remove();

      return;
    }

    gisElement.gisElementType = GisElementTypesEnum.NetworkElement;
    this.navigatedElement = gisElement;
  };

  private onVisibleLayersIdsChange = (x): void => {
    if (!this.map) {
      return;
    }

    this.removeMarker(x);
    this.layersUpdated();
  };

  private onVisibleThematicsIdsChange = (x): void => {
    if (!this.map || !x) {
      return;
    }

    if (!this.map.isStyleLoaded()) {
      this.map.once('idle', () => this.applyThematicLayers(x[0]));
      return;
    }

    this.applyThematicLayers(x[0]);
  };

  private onLeakYearsChange = (x): void => {
    if (!this.map) {
      return;
    }

    this.map.setStyle(this.style);
  };

  private onGeojsonFeaturesChange = (geojsonFeatures: GeoJSON.FeatureCollection) => {
    if (!this.map || !geojsonFeatures) {
      this.map.removeLayer(this._geojsonSourceName);
      this.map.removeSource(this._geojsonSourceName);
      this._featuresSource = null;
      return;
    }

    if (geojsonFeatures.features.length <= this._featureSizeLimit) {
      if (!this.map.isStyleLoaded()) {
        this.map.once('idle', () => this.applyGeojsonFeatures(geojsonFeatures, false));
        return;
      } else {
        this.applyGeojsonFeatures(geojsonFeatures, false);
      }
    } else {
      this.applyGeojsonFeaturesRecursive(geojsonFeatures.features, this._featureSizeLimit, 0);
    }
  };

  applyGeojsonFeaturesRecursive(features: any[], size: number, partCounter: number): void {
    const start = partCounter * size;
    const end = (partCounter + 1) * size;

    const currentFeatures = features.slice(start, end);
    if (currentFeatures.length > 0) {
      this.map.once('idle', () => {
        this.applyGeojsonFeatures(
          { type: 'FeatureCollection', features: currentFeatures },
          true,
          partCounter
        );
        this.applyGeojsonFeaturesRecursive(features, size, partCounter + 1);
      });
    }
  }

  private applyGeojsonFeatures(
    geojsonFeatures: GeoJSON.FeatureCollection,
    isMultiPart: boolean,
    count?: number
  ): void {
    const paintBaseLayerDefinition = this.mapOpacityEnabled
      ? this._paintBaseLayerWithMaxOpacity
      : null;

    const featuresLayer = this._sourcesService.getFeaturesLayer(
      this._featuresType,
      this._geojsonSourceName,
      paintBaseLayerDefinition
    );

    const validLayerTypes: Array<
      | 'symbol'
      | 'fill'
      | 'background'
      | 'circle'
      | 'fill-extrusion'
      | 'heatmap'
      | 'hillshade'
      | 'line'
      | 'raster'
      | 'custom'
    > = [
      'symbol',
      'fill',
      'background',
      'circle',
      'fill-extrusion',
      'heatmap',
      'hillshade',
      'line',
      'raster',
      'custom',
    ];

    if (isMultiPart) {
      const sourceName = this._geojsonSourceName + '_part' + count;
      this.map.addSource(sourceName, {
        type: 'geojson',
        data: geojsonFeatures,
      });
      this.map.addLayer({
        id: sourceName,
        type: validLayerTypes.includes(featuresLayer.type as any)
          ? (featuresLayer.type as any)
          : 'fill',
        source: sourceName,
        paint: featuresLayer.paint,
      });
    } else {
      this._featuresSource = geojsonFeatures as any;
      this.addGeoJsonSource();
      this.map.setStyle(this.style);
    }
  }

  private addGeoJsonSource(sources?): void {
    if (this.map.getLayer(this._geojsonSourceName)) {
      this.map.removeLayer(this._geojsonSourceName);
    }
    if (this.map.getSource(this._geojsonSourceName)) {
      this.map.removeSource(this._geojsonSourceName);
    }

    const payload: any = {
      type: 'geojson',
      data: this._featuresSource as any,
    };

    if (sources) {
      sources[this._geojsonSourceName] = payload;
    } else {
      this.map.addSource(this._geojsonSourceName, payload);
    }
  }

  onKpiInfoChange(kpiInfo: MapThematicKpi) {
    this.selectedKpiInfo = kpiInfo;
  }

  onMapThematicTooltipInfoChanges(tooltipInfo: MapThematicTooltipInfo) {
    this.selectedMapThematicTooltipInfo = tooltipInfo;
  }

  fitBounds(bounds: MapBounds, options?: any, eventData?: any): void {
    this.map?.fitBounds(bounds as any, options, eventData);
  }

  flyTo(options: any, eventData?: any) {
    if (!this.map) {
      return;
    }

    this.map.flyTo(options, eventData);
  }

  exportToPdf(): void {
    if (this.map) {
      const imageData = this.map.getCanvas().toDataURL('image/png', 1.0);
      const currentWidth = this.map.getCanvas().width;
      const currentHeight = this.map.getCanvas().height;

      let ratioProportion = this._defaultPageWidth / currentWidth;

      if (ratioProportion > this._defaultPageHeight / currentHeight) {
        ratioProportion = this._defaultPageHeight / currentHeight;
      }

      const width = currentWidth * ratioProportion;
      const height = currentHeight * ratioProportion;

      const pageWidthMid = this._defaultPageWidth / 2;
      const imageWidthMid = width / 2;
      let imageXCoord = pageWidthMid - imageWidthMid;

      if (imageXCoord === 0) {
        imageXCoord = 15;
      }

      const documentItemText = new PdfExportItem({
        data: 'Map',
        type: 'text',
        fontSize: 18,
        xCoords: 140,
        yCoords: 20,
        textOptions: { align: 'justify' },
      });

      const documentItemMap = new PdfExportItem({
        data: imageData,
        type: 'image',
        xCoords: imageXCoord,
        yCoords: 30,
        textOptions: { align: 'justify' },
      });

      const fileName = `${this._genericFilename}.pdf`;

      const document1 = new ExportPdfDocument({
        filename: fileName,
        items: [documentItemText, documentItemMap],
        height: height,
        width: width,
        unit: 'mm',
        orientation: 'landscape',
      });

      this._genericExportService.exportPdfDocument(document1);
    }
  }

  isEmpty(): boolean {
    return !this.map;
  }

  getObjectToExport() {
    return this.map.getCanvas().toDataURL('image/png', 1.0);
  }

  private interceptAddToken = (url, resourceType) => {
    if (url.startsWith(this._settingsService.gis.glUrl)) {
      return {
        url: url,
        headers: { Authorization: 'Bearer ' + this._authenticationService.currentToken },
      };
    }
  };

  private getOutOfRangeMapColor() {
    this._globals
      .getAlgorithmAttributes()
      .pipe(untilDestroyed(this))
      .subscribe((attributes) => {
        if (attributes) {
          this._outOfRangeMapColorValue = attributes.find(
            (f) => f.algorithmAttributeId === AlgorithmAttributesIds.OutOfRangeMapColor
          )?.algorithmAttributeValue;
        }
      });
  }

  private applyNavigation() {
    if (!this.map || !this.navigatedElement) {
      return;
    }

    this.removeMarker();
    this.cleanHeSelection();

    switch (this.navigatedElement.gisElementType) {
      case GisElementTypesEnum.HierarchyElement:
        this.navigateToHierarchyElement(this.navigatedElement);
        break;

      case GisElementTypesEnum.NetworkElement:
        this.navigateToNetworkElement(this.navigatedElement);
        break;

      case GisElementTypesEnum.Leaks:
        this.navigateToLeaks(this.navigatedElement);
        break;

      default:
        return;
    }
  }

  private initializeMapParametersWithValue(value: MapParameters) {
    if (value) {
      this._receivedMapParameters = value;

      if (this._receivedMapParameters.loadFromPersistency) {
        // try first get persisted settings from local storage
        const persistedMapsettings = this._mapHelperService.getPersistedData(
          value.settingKey,
          null,
          true
        );

        if (persistedMapsettings) {
          const finalSettings = this.initializeMapParameters(
            persistedMapsettings,
            this._receivedMapParameters
          );
          this.setInternalMapParameters(finalSettings);

          this._persistencyLoaded$.next();
        } else {
          // if there is no settings persisted in local storage, then get settings from DB
          this._mapHelperService
            .getPersistedMapSettings(value.settingArea, value.settingKey)
            .subscribe({
              next: (settings) => {
                const finalSettings = settings
                  ? this.initializeMapParameters(settings, this._receivedMapParameters)
                  : this._receivedMapParameters;

                this.setInternalMapParameters(finalSettings);

                this._persistencyLoaded$.next();
              },
              error: (error) => {
                console.log(error);
              },
            });
        }
      } else {
        this.setInternalMapParameters(value);
      }
    }
  }

  private cleanHeSelection() {
    if (this.hierarchyElements?.length === 0) {
      return;
    }

    this.hierarchyElements = [];
  }

  private setInternalMapParameters(value: MapParameters) {
    this._mapParameters = value;
    super.init();
  }

  private applyThematicLayers(sourceName: string) {
    // Only one thematic layer is visible at the time
    this.thematicLayers?.forEach((layer) => {
      if (layer.layout?.visibility === 'visible') {
        layer.layout.visibility = 'none';
        this.map?.setLayoutProperty(layer.id, 'visibility', 'none');
      }

      if (layer.id === sourceName || layer.id === `${sourceName}-border`) {
        layer.layout.visibility = 'visible';
        this.map?.setLayoutProperty(layer.id, 'visibility', 'visible');

        this.cleanZoneLayers();
      }
    });
  }

  private cleanZoneLayers() {
    if (this.visibleZoneLayersIds?.length > 0) {
      this.visibleLayersIds = this.visibleLayersIds.filter(
        (id) => !this.visibleZoneLayersIds.some((zone) => zone === id)
      );
    }
  }

  private cleanThematicLayers() {
    if (this.visibleZoneLayersIds?.length > 0 && this.visibleThematicsIds?.length > 0) {
      this.visibleThematicsIds = [];
    }
  }

  private navigateToHierarchyElement(gisElement: IGisElementDto) {
    this.applyVisibleLayer(+gisElement.properties.type);

    this.hierarchyElements = [gisElement.properties.he];
  }

  private navigateToNetworkElement(gisElement: IGisElementDto) {
    const layerId = +gisElement.properties.type;
    this.applyVisibleLayer(layerId);

    const center = this._mapHelperService.getCenterFromGeometry(gisElement.geometry.coordinates);

    this.setMarker(center, layerId);
  }

  private navigateToLeaks(gisElement: IGisElementDto) {
    let layerId = gisElement.properties?.['layer-id'];
    const leakYear = gisElement.properties?.year;

    if (!layerId || !leakYear) {
      return;
    }

    // The layerId property could include the year, so remove it
    if (layerId.length > 6) {
      layerId = layerId.slice(0, -2);
    }

    this.applyLeakYear(+leakYear);
    this.applyVisibleLayer(+layerId);

    const center = this._mapHelperService.getCenterFromGeometry(gisElement.geometry.coordinates);

    this.setMarker(center, +layerId);
  }

  private onMove = (event) => {
    this._popup.removeClassName('map-popup');
  };

  private onStyledata = (event: MapDataEvent) => {
    this._mapStyleLoaded = event['style'] !== undefined && event['style'] !== null;
  };

  private onClick = (event) => {
    const allFeatures: MapFeature[] = this.map?.queryRenderedFeatures(event.point);

    this._informPosition(event?.lngLat);

    if (this._popup) {
      this._popup.remove();
    }

    this._popup.addClassName('map-popup');

    if (!allFeatures || allFeatures.length === 0) {
      return;
    }

    const tooltipModels$: Observable<MapTooltip>[] = [];
    for (let feature of allFeatures) {
      const { properties } = feature;
      const gisLayerId = this._mapHelperService.getGisLayerId(properties);
      let model$: Observable<MapTooltip>;

      if (this._sourcesService.isHELayer(gisLayerId)) {
        model$ = this.buildHierarchyElementLayerTooltip(properties);
      } else if (this._sourcesService.isLeakLayer(gisLayerId)) {
        model$ = this.buildClickLeakLayerTooltip(properties);
      } else if (this._sourcesService.isNELayer(gisLayerId)) {
        model$ = this.buildNetworkElementLayerTooltip(properties);
      } else if (this._sourcesService.isActivityLayer(gisLayerId)) {
        model$ = this.buildActivitiesLayerTooltip(properties);
      }

      if (model$) {
        tooltipModels$.push(model$);
      }
    }

    forkJoin(tooltipModels$).subscribe((models) => {
      models = models.filter(Boolean);
      models = this._arrayHelperService.onlyUniqueByFn<MapTooltip>(models, this.areSameModels);

      if (models.length) {
        this.openTooltip(event, models);
      }
    });
  };

  private areSameModels = (modelA: MapTooltip, modelB: MapTooltip) => modelA.title === modelB.title;

  private openTooltip(event, models: MapTooltip[]): void {
    const element = this._dynamicRenderizerService.injectComponent(
      MapTooltipComponent,
      (cmp) => (cmp.models = models)
    );
    this._popup.setLngLat(event.lngLat).setDOMContent(element).addTo(event.target);
  }

  private buildHierarchyElementLayerTooltip(properties): Observable<MapTooltip> {
    if (!properties?.he) {
      return of(null);
    }

    const mapTooltipProperties$ = this._mapHelperService.getMapTooltipProperties(properties);

    return mapTooltipProperties$.pipe(
      take(1),
      map((mapTooltipProperties) => {
        const kpiTooltipProperties = this.includeThematicTooltip(properties);

        if (kpiTooltipProperties) {
          mapTooltipProperties.push(kpiTooltipProperties);
        }

        const navItems = this._mapHelperService.getHeNavItemsConfigurations(properties.he);
        const mapTooltipNavigations = this._mapHelperService.getMapTooltipNavigations(navItems, {
          hierarchyElementIds: [properties.he],
        });

        const mapTooltip = new MapTooltip({
          title: properties.he,
          properties: mapTooltipProperties,
          navigations: mapTooltipNavigations,
        });

        return mapTooltip;
      })
    );
  }

  private _informPosition(lngLat: any) {
    if (!lngLat) {
      return;
    }

    const coordinates = { lng: lngLat?.lng, lat: lngLat?.lat };
    this.coordinatesClicked.emit(coordinates);
  }

  private buildClickLeakLayerTooltip = (properties): Observable<MapTooltip> => {
    const mapTooltipProperties$ = this._mapHelperService.getMapTooltipProperties(properties);

    return mapTooltipProperties$.pipe(
      take(1),
      map((mapTooltipProperties) => {
        const kpiTooltipProperties = this.includeThematicTooltip(properties);

        if (kpiTooltipProperties) {
          mapTooltipProperties.push(kpiTooltipProperties);
        }

        const navItems = this._mapHelperService.getLeaksNavItemsConfigurations();
        const mapTooltipNavigations = this._mapHelperService.getMapTooltipNavigations(navItems, {
          orderNumber: properties['order-id'],
          creationDate: properties['o-date'],
        });

        const mapTooltip = new MapTooltip({
          title: properties.id,
          properties: mapTooltipProperties,
          navigations: mapTooltipNavigations,
        });

        return mapTooltip;
      })
    );
  };

  private includeThematicTooltip(properties: any): MapTooltipProperty | null {
    if (!this.selectedMapThematicTooltipInfo) {
      return null;
    }

    const propertyValue = +this._objectHelper.deepGet(
      properties,
      this.selectedMapThematicTooltipInfo?.thematicKey
    );
    if (isNaN(propertyValue)) {
      return null;
    }

    const conversionFactor = this.selectedMapThematicTooltipInfo?.conversionFactor;
    const value = +this._utilsHelperService.uomMultiply(
      String(propertyValue),
      String(conversionFactor)
    );

    if (value == undefined || value == null || isNaN(value)) {
      return null;
    }

    const valueFormatted = this._localizationHelper.formatNumber(value);

    const labelKey$ = this.selectedMapThematicTooltipInfo?.kpiSetting
      ? new KpiTitlePipe(this._localizationHelper).transform(
          this.selectedMapThematicTooltipInfo.kpiSetting
        )
      : undefined;

    return new MapTooltipProperty({
      key: this.selectedMapThematicTooltipInfo.thematicKey,
      categoryKey: this.selectedMapThematicTooltipInfo.categoryNameKey,
      labelKey: this.selectedMapThematicTooltipInfo.thematicNameKey,
      labelKey$: labelKey$,
      value: `${valueFormatted} ${this.selectedMapThematicTooltipInfo.unitLabel}`,
    });
  }

  private buildActivitiesLayerTooltip(properties: { [key: string]: string }) {
    const mapTooltipProperties$ = this._mapHelperService.getMapTooltipProperties(properties);

    return mapTooltipProperties$.pipe(
      take(1),
      map((mapTooltipProperties) => {
        const kpiTooltipProperties = this.includeThematicTooltip(properties);

        if (kpiTooltipProperties) {
          mapTooltipProperties.push(kpiTooltipProperties);
        }

        const activityId = properties['act-id'];
        const navItems = this._mapHelperService.getActivitiesNavItemsConfigurations();

        const mapTooltipNavigations = this._mapHelperService.getMapTooltipNavigations(navItems, {
          activityIds: [activityId],
          startDate: properties['start-date'],
          endDate: properties['end-date'],
        });

        const mapTooltip = new MapTooltip({
          title: properties['order-id'],
          properties: mapTooltipProperties,
          navigations: mapTooltipNavigations,
        });

        return mapTooltip;
      })
    );
  }

  private buildNetworkElementLayerTooltip(properties): Observable<MapTooltip> {
    if (!this.displayNeTooltip(properties)) {
      return of(null);
    }

    const mapTooltipProperties$ = this._mapHelperService.getMapTooltipProperties(properties);

    return mapTooltipProperties$.pipe(
      take(1),
      map((mapTooltipProperties) => {
        const kpiTooltipProperties = this.includeThematicTooltip(properties);

        if (kpiTooltipProperties) {
          mapTooltipProperties.push(kpiTooltipProperties);
        }

        const navItems = this._mapHelperService.getNeNavItemsConfigurations(
          properties.type,
          properties.ne
        );
        const mapTooltipNavigations = this._mapHelperService.getMapTooltipNavigations(navItems, {
          networkElementName: properties['ne-name'],
          networkElementId: properties.ne,
          propertyId: properties[this._customerPropertyIdAttribute],
        });

        const mapTooltip = new MapTooltip({
          title:
            properties['ne-name'] || properties[this._customerPropertyIdAttribute] || properties.id,
          properties: mapTooltipProperties,
          navigations: mapTooltipNavigations,
        });

        return mapTooltip;
      })
    );
  }

  private displayNeTooltip(properties) {
    if (!properties) {
      return false;
    }

    const isCustomerRelated = this._mapHelperService.isCustomerRelated(properties.type);
    if (isCustomerRelated) {
      return !!properties[this._customerPropertyIdAttribute];
    }

    return properties['ne-name'] || properties.id;
  }

  private loadIconsForSymbolLayers(layers: VectorSources) {
    const vectorSources = Object.entries<VectorSource>(layers);
    vectorSources.forEach((vectorSource) => {
      const layer = vectorSource[1];

      if (
        layer.isZone ||
        layer.isLine ||
        this._sourcesService.isLeakLayer(layer.gisLayerId) ||
        this._sourcesService.isActivityLayer(layer.gisLayerId) ||
        !layer.iconPath
      ) {
        return;
      }

      const svgIcon = new SvgIcon({
        path: layer.iconPath,
        color: layer.iconColor,
        strokeColor: layer.iconStrokeColor,
      });

      this.layerIcons.set(layer.gisLayerId, svgIcon);
      const imgName = `layer-${layer.gisLayerId}-icon`;
      this.addSvgIcon(svgIcon.path, imgName);
    });
  }

  private addSvgIcon(svgIconPath: string, imgName: string, sdf = false): void {
    const svgBase64 = btoa(svgIconPath);
    const imgSource = `data:image/svg+xml;base64,${svgBase64}`;
    this.addImage(imgSource, imgName, sdf);
  }

  private addImage(path: string, imgName: string, sdf = false): void {
    const img = new Image(30, 30);
    img.onload = () => {
      if (this.map && !this.map.hasImage(imgName)) {
        // Need sdf so the icon can be colored with mapbox api.
        this.map.addImage(imgName, img, { sdf });
      }
    };

    img.src = path;
  }

  private addMergedZonesBgImage(): void {
    const svgPath = `/assets/svg/${this._mergedZonesPatternSource}.svg`;

    const img = new Image(30, 30);
    img.onload = () => {
      const imgName = this._mergedZonesPatternSource;

      if (!this.map?.hasImage(imgName)) {
        this.map?.addImage(imgName, img, { sdf: false });
      }
    };
    img.src = svgPath;
  }

  private getFeatures(
    gisCollection: IGisCollectionDto
  ): geo.Feature<geo.Geometry> | geo.FeatureCollection<geo.Geometry> {
    const features = gisCollection as unknown as geo.FeatureCollection<geo.Geometry>;
    return features;
  }

  private applyVisibleLayer(layerId: number): void {
    if (layerId && !this.visibleLayersIds.includes(layerId)) {
      this.visibleLayersIds = [...this.visibleLayersIds, layerId];
    }
  }

  private applyLeakYear(leakYear: number): void {
    const yearValue = this._mapHelperService.getFiltrableYearValue(leakYear);
    const isVisibleYear = this.leakYears.some((y) => y === yearValue);
    if (!isVisibleYear) {
      this.leakYears = [...this.leakYears, yearValue];
    }
  }

  private setMarker(center: [number, number], layerId?: number) {
    this.removeMarker();

    this._marker = new MapMarker(layerId, { color: '#146ab1' }).setLngLat(center).addTo(this.map);

    this.map.setCenter(center);
    this.map.setZoom(this._navigationZoom);
  }

  private removeMarker(excludeLayers?: number[]) {
    if (!this._marker) {
      return;
    }

    const isMarkerLayerVisible = excludeLayers?.some((layerId) => this._marker.layerId === layerId);
    if (isMarkerLayerVisible) {
      return;
    }

    this._marker.remove();
  }

  private displayMessage(dialogSettings: WlmDialogSettings) {
    if (!dialogSettings) {
      return;
    }

    this._dialogService.showTranslatedMessageInSnackBar(dialogSettings);
  }

  private getVisibleVectorSources(): [string, VectorSource][] {
    const allSources = Object.entries<VectorSource>(this._vectorSources);

    let extendedVisibleLayerIds = [];
    this._visibleLayersIds.forEach((visibleLayerId) => {
      if (
        this._sourcesService.isLeakLayer(visibleLayerId) ||
        this._sourcesService.isActivityLayer(visibleLayerId)
      ) {
        const datedLayerIdsByYear = this._mapHelperService.getDatedLayerIdsByYearFilters(
          visibleLayerId,
          this.leakYears
        );

        extendedVisibleLayerIds = extendedVisibleLayerIds.concat(datedLayerIdsByYear);
      } else {
        extendedVisibleLayerIds.push(visibleLayerId);
      }
    });

    return allSources.filter((source) => extendedVisibleLayerIds.includes(source[1].gisLayerId));
  }

  private notifyMapSettingsChanges() {
    this._mapHelperService.persistMapSettingsLocally(
      this.mapSettings,
      this.mapParameters.settingKey
    );

    this._cd.detectChanges();
  }

  private getCurrentMapTheme(): MapThemes {
    const appTheme = this._themeService.currentTheme;
    const mapTheme = mapThemesMapping[appTheme];
    return mapTheme;
  }

  private listenThemeChanges(): void {
    // Listen only changes that happen after the map has loaded.
    this._themeChangeSubs?.unsubscribe();
    this._themeChangeSubs = this._themeService.themeChanges$
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.refreshMapStyle();
      });
  }

  private buildDefaultIcons(): void {
    this._iconLoaderService.getNamedSvgIcon(mapDefaults.iconName).subscribe((svg) => {
      const serializer = new XMLSerializer();
      const svgStr = serializer.serializeToString(svg);
      this.addSvgIcon(svgStr, mapDefaults.iconName, true);
    });
  }

  refreshMapStyle(): void {
    if (!this.map) {
      return;
    }

    this._mapTheme = this.getCurrentMapTheme();
    this._sourcesService.getBaseSource(this._mapTheme, this.baseType).subscribe((baseLayer) => {
      this._baseSource = baseLayer;
      this.map.setStyle(this.style);
    });
  }
}
