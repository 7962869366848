import { inject, Injectable } from '@angular/core';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { UoMService } from '@common-modules/shared/uom/uom.service';
import { BehaviorSubject, map, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OffsetUnitService {
  private readonly _dimensionTypeId = 2;
  private readonly _timeAggregationId = 2;

  private readonly _uomService = inject(UoMService);

  private _unitConversion: UnitTypeConversionViewDto;
  private _unitConversion$ = new ReplaySubject<UnitTypeConversionViewDto>(1);
  readonly unitConversion$ = this._unitConversion$.asObservable();

  private _offsetUnit$ = new BehaviorSubject<string>('');
  readonly offsetUnit$ = this._offsetUnit$.asObservable();

  buildOffsetUnit(hierarchyElementTypeId: string): Observable<void> {
    return this._uomService
      .getByParams(this._dimensionTypeId, this._timeAggregationId, hierarchyElementTypeId)
      .pipe(
        map((unitConversion) => {
          this._unitConversion = unitConversion;
          this._unitConversion$.next(unitConversion);

          if (!unitConversion) {
            console.error(
              `There is no pressure conversion for heTypeId: ${hierarchyElementTypeId} and timeAggregationId: ${this._timeAggregationId}`
            );
          }

          this.setOffsetUnit(unitConversion);
        })
      );
  }

  private setOffsetUnit(unitConversion): void {
    const offsetUnit = unitConversion ? `[${unitConversion.unitTypeToDescription}]` : '';
    this._offsetUnit$.next(offsetUnit);
  }

  convertFromUom(value: number): number {
    if (!value || !this._unitConversion) {
      return value;
    }

    return +value * this._unitConversion?.conversionFactor;
  }

  convertToUom(value: number): number {
    if (!value || !this._unitConversion || this._unitConversion?.conversionFactor == 0) {
      return value;
    }

    return +value / this._unitConversion?.conversionFactor;
  }
}
