import { Component, Injector, OnInit, inject } from '@angular/core';
import { INetworkElementTypeDto } from '@common-modules/dependencies/ne/network-element-type.dto';
import { GenericCrudSettings } from '@common-modules/generic-crud/generic-crud-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { ValidationHelperService } from '@common-modules/shared/helpers/validation-helper.service';
import { Observable, map, of } from 'rxjs';
import { NeTypeFormComponent } from '../ne-type-form/ne-type-form.component';
import { NETypesCrudService } from '../ne-types-crud.service';

const COMPONENT_SELECTOR = 'wlm-ne-type-crud';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-type-crud.component.html',
  styleUrls: ['./ne-type-crud.component.scss'],
})
export class NeTypeCrudComponent implements OnInit {
  settings: GenericCrudSettings;
  selectedItem: INetworkElementTypeDto;
  isZone: boolean = false;
  private readonly _injector = inject(Injector);
  private readonly _neTypesCrudService = inject(NETypesCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'NETypesCrudService',
      grid: {
        gridSettingsName: 'NetworkElementTypesCrud',
      },
      create: {
        formComponent: NeTypeFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: NeTypeFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(false),
      },
      delete: {
        disableHook: this.disableHook,
      },
    });
  }

  private disableHook = (model: INetworkElementTypeDto): Observable<boolean> => {
    const isReadonly = this._neTypesCrudService.isReadonlyType(model.networkElementTypeId);
    return of(isReadonly);
  };

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (model: INetworkElementTypeDto): Observable<INetworkElementTypeDto | null> => {
      return this._neTypesCrudService.validate(model, isCreate).pipe(
        map((validation) => {
          if (validation.isDuplicatedId) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.network-element-type-id`
            );
            return null;
          }
          return this.sanitizeModel(model);
        })
      );
    };

  private sanitizeModel(model: INetworkElementTypeDto): INetworkElementTypeDto {
    if (model?.iconPath) {
      model.iconPath = globalUtilsHelper.purifySvg(model.iconPath);
    }
    return model;
  }
}
