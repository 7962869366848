import { Injectable, Injector } from '@angular/core';
import { AdditionalHttpOpts } from '@common-modules/cache/http-cache/additional-http-options';
import { HttpCacheClient } from '@common-modules/cache/http-cache/http-cache.client';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { ObjectHelperService } from '@common-modules/shared/helpers/object-helper.service';
import { DateFormats } from '@common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { UnitTypeConversionViewDto } from '@common-modules/shared/model/uom/unit-type-conversion-view.dto';
import { HistoricalChartDataParameters } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-chart-data-parameters';
import { HistoricalChartResultSettings } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-chart-result-settings';
import { HistoricalEvent } from '@common-modules/wlm-charts/core/models/historical-chart-settings/historical-event';
import {
  BaseHistoricalChartService,
  Translations,
} from '@common-modules/wlm-charts/core/services/base-historical-chart.service';
import { HierarchyElementPressureConfigDto } from '@water-loss//features/shared/model/signals/hierarchy-element-pressure-config.dto';
import { HierarchyElementPressureSignalVersionDto } from '@water-loss//features/shared/model/signals/hierarchy-element-pressure-signal-version.dto';
import { HierarchyElementPressureSignalVersionOperationDto } from '@water-loss//features/shared/model/signals/hierarchy-element-signal-version-operation.dto';
import { SavePressureSignalVersionResponseDto } from '@water-loss//features/shared/model/signals/save-pressure-signal-version-reponse.dto';
import { Observable, combineLatest, forkJoin, map, of, take } from 'rxjs';
import { HierarchyElementPressureSignalVersionSaveDto } from '../../shared/model/signals/hierarchy-element-pressure-signal-version-operation.dto';
import { OffsetUnitService } from './offset-unit.service';

@Injectable()
export class PressureHistoricalConfigService extends BaseHistoricalChartService {
  protected get url() {
    return `${this.apiUrl}/pressure/historical`;
  }

  private readonly _seriesInUtc = false;

  constructor(
    injector: Injector,
    private readonly _localizationService: LocalizationHelperService,
    private readonly _http: HttpCacheClient,
    private readonly _objectHelper: ObjectHelperService,
    private readonly _offsetUnitService: OffsetUnitService
  ) {
    super(injector);
  }

  saveHistoricalConfiguration(
    operations: HierarchyElementPressureSignalVersionOperationDto[],
    currentConfigurations: HierarchyElementPressureSignalVersionDto[],
    originalConfigurations: HierarchyElementPressureSignalVersionDto[],
    element: INetworkElementDto
  ): Observable<SavePressureSignalVersionResponseDto> {
    const signals = operations
      .filter((x) => x.originalWasCurrent)
      .map((x) => this.getCurrentFromHistorical(x.originalConfiguration));
    const trackedDates = this.getDaysToRecalculate(currentConfigurations, originalConfigurations);
    const trackedDatesNoUtc = trackedDates.map((date) =>
      this.dateHelperService.toApiFormatNoUTC(date)
    );

    operations.forEach((config) => {
      config.configuration.hierarchyElementId = element.hierarchyElementId;
      config.originalConfiguration.hierarchyElementId = element.hierarchyElementId;
      config.configuration.offset = this._offsetUnitService.convertToUom(
        config.configuration.offset
      );
    });

    const saveCmd = new HierarchyElementPressureSignalVersionSaveDto({
      pressureSignalVersionOperations: operations,
      minDate: this.dateHelperService.toApiFormatNoUTC(this.minDate),
      pressureSignals: signals,
      hierarchyElementId: element.hierarchyElementId,
      hierarchyElementName: element.hierarchyElementName ?? element.networkElementName,
      trackedSignals: this.arrayHelperService.onlyUnique(
        operations.map((x) => x.configuration.signalId)
      ),
      trackedDates: trackedDatesNoUtc,
    });

    const operationsPath = 'pressureSignalVersionOperations[]';
    const requestDatesNoUtc = [
      'minDate',
      `${operationsPath}.configuration.validFrom`,
      `${operationsPath}.configuration.validTo`,
      `${operationsPath}.originalConfiguration.validFrom`,
      `${operationsPath}.originalConfiguration.validTo`,
      'trackedDates[]',
    ];
    const additionalHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: true,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });

    return this.httpCacheClient.post<SavePressureSignalVersionResponseDto>(
      `${this.url}/save`,
      saveCmd,
      this.avoidCache,
      additionalHttpOptions
    );
  }

  cloneConfiguration(
    configuration: HierarchyElementPressureSignalVersionDto
  ): HierarchyElementPressureSignalVersionDto {
    const cloned = new HierarchyElementPressureSignalVersionDto({ ...configuration });
    return cloned;
  }

  getData(
    params: HistoricalChartDataParameters
  ): Observable<HierarchyElementPressureSignalVersionDto[]> {
    this.setDatesThreshold(params);

    const queryParams = {
      hierarchyElementId: params.queryParams['hierarchyElementId'],
      minDate: this.dateHelperService.toApiFormatNoUTC(params.startDate),
    };

    const requestDatesNoUtc = ['startDate', 'endDate'];

    const additionalHttpOptions = new AdditionalHttpOpts({
      mapRequestDates: true,
      mapResponseDates: false,
      requestDatesNoUtc,
      warnIfMissing: requestDatesNoUtc,
    });

    return this.httpCacheClient
      .get<HierarchyElementPressureSignalVersionDto[]>(
        this.url,
        this.avoidCache,
        queryParams,
        additionalHttpOptions
      )
      .pipe(
        map((data) =>
          data.map((item) => {
            return new HierarchyElementPressureSignalVersionDto({
              ...item,
              validFrom: this.preprocessEventDate(item.validFrom.toString()),
              validTo: this.preprocessEventDate(item.validTo.toString()),
              offset: this._offsetUnitService.convertFromUom(item.offset),
            });
          })
        )
      );
  }

  private preprocessEventDate(dateStr: string): Date {
    const isUTC = false;
    const date = this.dateHelperService.fromApiFormat(dateStr, isUTC);
    return date;
  }

  mapDataToGenericSettings(
    data: HierarchyElementPressureSignalVersionDto[]
  ): Observable<HistoricalChartResultSettings> {
    const events$ = data.map((versionDto) => {
      const event = this.getEvent(versionDto);
      return event;
    });

    if (events$.length === 0) {
      const settings = new HistoricalChartResultSettings({
        events: [],
        showInUTC: this._seriesInUtc,
      });
      return of(settings);
    }

    return forkJoin(events$).pipe(
      map((events) => {
        const sortedEvents = this._objectHelper.sortObjectArray(events, 'group', 'ascending');
        const settings = new HistoricalChartResultSettings({
          events: sortedEvents,
          showInUTC: this._seriesInUtc,
        });
        return settings;
      })
    );
  }

  getEvent(versionDto: HierarchyElementPressureSignalVersionDto): Observable<HistoricalEvent> {
    const pressureUnit$ = this._offsetUnitService.unitConversion$;
    const ts$ = this._localizationService.get(this.getTranslationKey());

    return combineLatest([pressureUnit$, ts$]).pipe(
      take(1),
      map(([pressureUnit, ts]) => {
        const additionalParams = {
          ...versionDto,
        };

        const endDate = this.dateHelperService.fromApiFormat(versionDto.validTo.toString());
        const startDate = this.dateHelperService.fromApiFormat(versionDto.validFrom.toString());
        const visibleEndDate = endDate > this.maxDate ? this.maxDate : new Date(endDate);
        const visibleStartDate = startDate < this.minDate ? this.minDate : new Date(startDate);

        const id = versionDto.id;
        const group = this.getGroup(versionDto);
        const label = this.getLabel(versionDto, ts);
        const tooltip = this.getTooltip(versionDto, ts, pressureUnit);

        const event = new HistoricalEvent({
          additionalParams,
          endDate,
          startDate,
          visibleEndDate,
          visibleStartDate,
          id,
          group,
          label,
          originalEndDate: new Date(endDate),
          originalStartDate: new Date(startDate),
          tooltip,
        });

        return event;
      })
    );
  }

  saveConfiguration(data: HierarchyElementPressureSignalVersionSaveDto): Observable<void> {
    return this._http.post(`${this.url}/save`, data);
  }

  protected getGroup(configuration: HierarchyElementPressureSignalVersionDto): string {
    return configuration.pointDescription;
  }

  protected getLabel(config: HierarchyElementPressureSignalVersionDto, ts: Translations): string {
    const pressureType = ts[`pressure-type-${config.pressureTypeId}`];
    return `${config.pointDescription} ${pressureType}`;
  }

  protected getTooltip(
    config: HierarchyElementPressureSignalVersionDto,
    ts: Translations,
    pressureUnit: UnitTypeConversionViewDto
  ): string {
    let startDate;
    if (this.dateHelperService.isCurrentStartDate(config.validFrom)) {
      startDate = (ts.current as any).start;
    } else {
      startDate = this._localizationService.formatDate(config.validFrom, DateFormats.Date);
    }

    let endDate;
    if (this.dateHelperService.isCurrentEndDate(config.validTo)) {
      endDate = (ts.current as any).end;
    } else {
      endDate = this._localizationService.formatDate(config.validTo, DateFormats.Date);
    }

    let tooltip = [
      this.getTooltipRow(ts.pointId, config.pointId),
      this.getTooltipRow(ts.pointDescription, config.pointDescription),
      this.getTooltipRow(ts.configurationType, ts[`pressure-type-${config.pressureTypeId}`]),
    ];

    if (config.offset) {
      tooltip.push(
        this.getTooltipRow(
          ts.offset,
          config.offset.toString() + ' ' + pressureUnit?.unitTypeToDescription
        )
      );
    }

    tooltip.push(
      ...[this.getTooltipRow(ts.startDate, startDate), this.getTooltipRow(ts.endDate, endDate)]
    );

    return tooltip.join('<br>');
  }

  private getTooltipRow = (header: string, value: string): string =>
    `<strong>${header}:</strong> ${value}`;

  protected getTranslationKey(): string {
    return `${this.T_SCOPE}.pressure`;
  }

  getCurrentFromHistorical(
    config: HierarchyElementPressureSignalVersionDto
  ): HierarchyElementPressureConfigDto {
    if (!config) {
      return null;
    }
    const current: HierarchyElementPressureConfigDto = {
      signalId: config.signalId,
      pressureTypeId: config.pressureTypeId,
      offset: config.offset,
      offsetCoordinates: config.offsetCoordinates,
      pointDescription: config.pointDescription,
      pointId: config.pointId,
      hierarchyElementId: config.hierarchyElementId,
    };
    return current;
  }

  configurationsAreEqual<HierarchyElementPressureConfigDto>(
    config1: HierarchyElementPressureConfigDto,
    config2: HierarchyElementPressureConfigDto
  ) {
    return this._objectHelper.deepEqual(config1, config2);
  }

  recalculateDatesGroupBy(configuration: HierarchyElementPressureSignalVersionDto): string {
    if (configuration.pointId === undefined) {
      throw new Error(
        'Could not get pointId. Maybe the configuration is not a HierarchyElementPressure one?'
      );
    }
    const { pointId, pressureTypeId } = configuration;
    return [pointId, pressureTypeId].join('#');
  }
}
