import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BehaviorSubject, map, Observable, ReplaySubject } from 'rxjs';
import {
  IntegrableForm,
  IntegrableFormParams,
} from 'src/app/common-modules/shared/forms/integrable-form';
import { globalUtilsHelper } from 'src/app/common-modules/shared/helpers/global-utils-helper';
import { SpinnerService } from 'src/app/common-modules/wlm-spinner/spinner.service';
import { GisLayerKpiConversionDto } from '../gis-layer-kpi-conversion.dto';
import { GisLayerKpiCrudService } from '../gis-layer-kpi-crud-service';
import { GisLayerKpiConfigurationDto } from '../gis-layer-kpi.dto';
import { KpiSummaryDto } from '../kpi-summary.dto';
import { SummaryTable } from '../summary-table.dto';

@Component({
  selector: 'wlm-gis-layer-kpi-form',
  templateUrl: './gis-layer-kpi-form.component.html',
  styleUrl: './gis-layer-kpi-form.component.scss',
})
export class GisLayerKpiFormComponent extends IntegrableForm implements OnInit {
  @Input() initialModel: GisLayerKpiConversionDto = null;
  private readonly _destroyRef = inject(DestroyRef);
  model: GisLayerKpiConfigurationDto = {} as GisLayerKpiConfigurationDto;
  gisLayerId: number;
  private readonly _gisLayerKpiCrudService = inject(GisLayerKpiCrudService);
  private readonly _globalService = inject(GlobalsService);
  private readonly _spinnerService = inject(SpinnerService);
  private setLoading: (loading: boolean) => void;
  summaryTableOptions$ = new BehaviorSubject<SummaryTable[]>([]);
  fieldNamesOptions$ = new BehaviorSubject<string[]>([]);
  selectedSummaryTable$ = new ReplaySubject<string>();

  constructor() {
    super();
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {
    this._gisLayerKpiCrudService.getSummaryTables().subscribe((options) => {
      this.summaryTableOptions$.next(options);
    });
  }

  onModelChange(model: GisLayerKpiConfigurationDto): void {
    this.model = globalUtilsHelper.clone(model, true);
  }

  onIsValid(isValid: boolean): void {
    this.setIsValid(isValid);
  }

  onHasChanges(hasChanges: boolean): void {
    this.setHasChanges(hasChanges);
  }

  getModel() {
    return this.model;
  }

  setInitialModel(model: any): void {
    this.initialModel = globalUtilsHelper.clone(model, true);
  }

  setParams(params: IntegrableFormParams): void {
    this.formOptions.formState.op = params.op;
    this.formOptions = globalUtilsHelper.clone(this.formOptions);
    this.gisLayerId = params.filters.gisLayerId;

    if (this.gisLayerId) {
      this.model.gisLayerId = this.gisLayerId;
      if (this.formOptions.formState.op === 'create') {
        this.setInitialModel(this.model);
      } else {
        const kpiSummary: KpiSummaryDto = {
          kpiType: this.initialModel.kpiType,
          kpiProperty: this.initialModel.kpiProperty,
          dimensionTypeId: this.initialModel.dimensionTypeId,
          timeAggregationId: this.initialModel.timeAggregationId,
        };

        const gisLayerKpiConfig: GisLayerKpiConfigurationDto = {
          gisLayerId: this.initialModel.gisLayerId,
          kpiType: this.initialModel.kpiType,
          kpis: [kpiSummary],
        };
        this.setInitialModel(gisLayerKpiConfig);
      }
    }
  }

  formOptions: FormlyFormOptions = {
    formState: {},
  };

  readonly fieldConfig: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'gisLayerId',
          type: 'input',
          className: 'col-12',
          hide: true,
          props: {
            disabled: true,
            type: 'number',
            label: 'GisLayerId',
            required: true,
          },
        },
        {
          key: 'kpiType',
          type: 'select',
          className: 'col-12',
          props: {
            type: 'text',
            label: 'Summary Table',
            valueProp: 'summaryTable',
            labelProp: 'summaryTable',
            required: true,
            options: this.summaryTableOptions$,
          },
          hooks: {
            onInit: (field) => {
              (field.props.options as Observable<any[]>)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe((options) => {
                  field.formControl.valueChanges
                    .pipe(takeUntilDestroyed(this._destroyRef))
                    .subscribe((value) => {
                      const selectedTable = value;
                      this.selectedSummaryTable$.next(selectedTable);
                      this.summaryTableOptions$
                        .pipe(
                          map((summaryTables: SummaryTable[]) =>
                            summaryTables.find((table) => table.summaryTable === selectedTable)
                          )
                        )
                        .subscribe((table) => {
                          if (table) {
                            this.fieldNamesOptions$.next(table.fieldNames);
                          }
                        });
                    });
                });
            },
          },
          expressions: {
            'props.disabled': (field) => {
              return this.formOptions.formState.op === 'update';
            },
          },
        },
        {
          key: 'kpis',
          type: 'repeat',
          className: 'col-12',
          props: {
            addDefaultRecord: true,
          },
          expressions: {
            'props.disableButtons': (data) => this.formOptions.formState.op === 'update',
          },
          fieldArray: {
            fieldGroup: [
              {
                key: 'kpiType',
                type: 'input',
                className: 'col-12',
                hide: true,
                props: {
                  label: 'kpi Type',
                  required: true,
                },
                hooks: {
                  onInit: (field) => {
                    this.selectedSummaryTable$
                      .pipe(takeUntilDestroyed(this._destroyRef))
                      .subscribe((selectedTable) => {
                        field.formControl.setValue(selectedTable);
                      });
                  },
                },
              },
              {
                key: 'kpiProperty',
                type: 'select',
                className: 'col-12',
                props: {
                  label: 'Field Name',
                  required: true,
                  options: this.fieldNamesOptions$.pipe(
                    map((fieldNames: string[]) =>
                      fieldNames.map((fieldName: string) => ({
                        label: fieldName,
                        value: fieldName,
                      }))
                    )
                  ),
                },
                expressions: {
                  'props.disabled': (data) => this.formOptions.formState.op === 'update',
                },
              },
              {
                key: 'timeAggregationId',
                type: 'number',
                hide: true,
                props: {
                  label: 'Time Aggregation',
                },
                hooks: {
                  onInit: (field) => {
                    field.formControl.setValue(2);
                  },
                },
              },
              {
                key: 'dimensionTypeId',
                type: 'select',
                className: 'col-6',
                props: {
                  label: 'Dimension Type',
                  required: true,
                  valueProp: 'dimensionTypeId',
                  labelProp: 'dimensionTypeDescription',
                  options: this._globalService.getDimensionTypes(),
                },
              },
            ],
          },
        },
      ],
    },
  ];
}
