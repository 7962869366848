import { Injectable, Injector } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { GenericCrudService } from 'src/app/common-modules/generic-crud/generic-crud.service';
import { BaseService } from 'src/app/common-modules/shared/base.service';
import { ConfigurableGisLayerDto } from './configurable-gis-layer-dto';
import { GisLayerKpiConversionDto } from './gis-layer-kpi-conversion.dto';
import { GisLayerKpiConfigurationDto } from './gis-layer-kpi.dto';
import { SummaryTable } from './summary-table.dto';

@Injectable()
export class GisLayerKpiCrudService extends BaseService implements GenericCrudService<any, number> {
  private _entityUrl: string;

  constructor(injector: Injector, private _http: HttpClient) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/map-kpi-summary`;
  }

  getAll(data: { gisLayerId: number }): Observable<GisLayerKpiConversionDto[]> {
    if (!data?.gisLayerId) {
      return of([]);
    }
    return this.httpCacheClient
      .get<{ gisLayerId: number; kpis: any[] }>(`${this._entityUrl}/${data.gisLayerId}`)
      .pipe(
        map((response) => {
          if (!response || !response.kpis) {
            return [];
          }
          return response.kpis.map((kpi) => ({
            gisLayerId: response.gisLayerId,
            kpiType: kpi.kpiType,
            kpiProperty: kpi.kpiProperty,
            dimensionTypeId: kpi.dimensionTypeId,
            timeAggregationId: kpi.timeAggregationId,
          }));
        })
      );
  }

  create(entity: GisLayerKpiConfigurationDto): Observable<GisLayerKpiConfigurationDto> {
    return this.getAllKpis({ gisLayerId: entity.gisLayerId }).pipe(
      switchMap((kpiResponse: GisLayerKpiConfigurationDto) => {
        const kpis = kpiResponse?.kpis || [];
        entity.kpis.push(...kpis);
        return this.httpCacheClient.post<GisLayerKpiConfigurationDto>(this._entityUrl, entity);
      })
    );
  }

  update(entity: GisLayerKpiConfigurationDto): Observable<GisLayerKpiConfigurationDto> {
    return this.getAllKpis({ gisLayerId: entity.gisLayerId }).pipe(
      switchMap((kpiResponse: GisLayerKpiConfigurationDto) => {
        const kpiToUpdate = entity.kpis[0];
        const updatedKpis = kpiResponse.kpis.filter(
          (responseKpi) =>
            !(
              responseKpi.kpiType === kpiToUpdate.kpiType &&
              responseKpi.kpiProperty === kpiToUpdate.kpiProperty
            )
        );
        updatedKpis.push(kpiToUpdate);
        entity.kpis = updatedKpis;
        return this.httpCacheClient.post<GisLayerKpiConfigurationDto>(this._entityUrl, entity);
      })
    );
  }

  delete(id: number, entity: GisLayerKpiConfigurationDto): Observable<boolean> {
    return of();
  }

  deleteConfiguration(kpis: GisLayerKpiConfigurationDto): Observable<boolean> {
    return this.httpCacheClient
      .delete(`${this._entityUrl}/`, undefined, undefined, undefined, kpis)
      .pipe(map(() => true));
  }

  getId(entity: any): number {
    return entity.gisLayerId;
  }

  getConfigurableGisLayers(): Observable<ConfigurableGisLayerDto[]> {
    return this.httpCacheClient.get(`${this._entityUrl}`);
  }

  getSummaryTables(): Observable<SummaryTable[]> {
    return this.httpCacheClient.get(`${this._entityUrl}/summary-tables`);
  }

  getAllKpis(data: { gisLayerId: number }): Observable<any> {
    return this.httpCacheClient
      .get<GisLayerKpiConfigurationDto[]>(`${this._entityUrl}/${data.gisLayerId}`)
      .pipe(
        map((response) => {
          if (!response) {
            return [];
          }
          return response;
        })
      );
  }

  validate(entity: GisLayerKpiConfigurationDto): Observable<boolean> {
    return this.getAllKpis({ gisLayerId: entity.gisLayerId }).pipe(
      map((existingKpis: GisLayerKpiConfigurationDto) => {
        if (!existingKpis || !existingKpis.kpis) {
          return true;
        }
        for (const newKpi of entity.kpis) {
          if (
            existingKpis.kpis.some((existingKpi) => existingKpi.kpiProperty === newKpi.kpiProperty)
          ) {
            return false;
          }
        }
        return true;
      })
    );
  }
}
