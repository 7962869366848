<div *ngFor="let field of field.fieldGroup; let i = index" class="field-group">
  <formly-field [field]="field"></formly-field>
  <button
    *ngIf="!props.disableButtons"
    wlmCollapsibleButton
    [tooltip]="'common.remove'"
    (click)="remove(i)"
    class="remove-button"
  >
    <wlm-icon icon="delete"></wlm-icon>
  </button>
</div>
<div class="add-button-container" *ngIf="!props.disableButtons">
  <button wlmCollapsibleButton [tooltip]="'common.create'" (click)="add()" class="add-button">
    <wlm-icon icon="add"></wlm-icon>
  </button>
</div>
