import { Component } from '@angular/core';
import { FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { SvgEditorComponent } from '../svg-editor/svg-editor.component';

@Component({
  selector: 'wlm-svg-editor-field-type',
  templateUrl: './svg-editor-field-type.component.html',
  styleUrls: ['./svg-editor-field-type.component.scss'],
  standalone: true,
  imports: [SvgEditorComponent, FormlyModule],
})
export class SvgEditorFieldTypeComponent extends FieldType<FieldTypeConfig> {}
