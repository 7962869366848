import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RepeatComponent } from '@common-modules/shared-component/repeat/repeat.component';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { TypeOption, ValidatorOption, WrapperOption } from '@ngx-formly/core/lib/models';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { ChipsSelectorComponent } from '../shared-component/chips-selector/chips-selector.component';
import { TreeDropdownComponent } from '../shared-component/tree-dropdown/tree-dropdown.component';
import { CommonSharedModule } from '../shared/common-shared.module';
import { FileUploadModule } from '../shared/file-upload/file-upload.module';
import { LocalizationHelperService } from '../shared/localization/localization-helper.service';
import { ChipsInputTypeComponent } from './chips-input-type/chips-input-type.component';
import { ChipsSelectorTypeComponent } from './chips-selector-type/chips-selector-type.component';
import { formlyConfigBuilder } from './config-builder.formly';
import { ConfigurableFormComponent } from './configurable-form/configurable-form.component';
import { RepeatTypeComponent } from './repeat-type/repeat-type.component';
import { configurableFormTypes } from './types.formly';
import { ColorPickerTypeComponent } from './types/color-picker-type/color-picker-type.component';
import { FileUploadTypeComponent } from './types/file-upload-type/file-upload-type.component';
import { SwitcherTypeComponent } from './types/switcher-type/switcher-type.component';
import { TreeDropdownTypeComponent } from './types/tree-dropdown-type/tree-dropdown-type.component';
import { configurableFormValidations } from './validations.formly';
import { configurableFormWrappers } from './wrappers.formly';
import { SectionWrapperComponent } from './wrappers/section-wrapper/section-wrapper.component';

export class ConfigurableFormExternalSettings {
  wrappers?: WrapperOption[];
  types?: TypeOption[];
  validators?: ValidatorOption[];
}

@NgModule({
  declarations: [
    ConfigurableFormComponent,
    SectionWrapperComponent,
    ColorPickerTypeComponent,
    FileUploadTypeComponent,
    SwitcherTypeComponent,
    ChipsInputTypeComponent,
    ChipsSelectorTypeComponent,
    TreeDropdownTypeComponent,
    RepeatTypeComponent,
  ],
  imports: [
    CommonModule,
    CommonSharedModule,
    MatCardModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    FormlyModule.forRoot({
      wrappers: configurableFormWrappers,
      types: configurableFormTypes,
      validators: configurableFormValidations,
      // Validation messages included in formlyConfigBuilder
    }),

    FormlyMaterialModule,
    FormlyMatFormFieldModule,
    FormlyMatDatepickerModule,
    FileUploadModule,
    ChipsSelectorComponent,
    TreeDropdownComponent,
    RepeatComponent,
  ],
  exports: [ConfigurableFormComponent],
  providers: [
    {
      provide: FORMLY_CONFIG,
      multi: true,
      useFactory: formlyConfigBuilder,
      deps: [LocalizationHelperService],
    },
  ],
})
export class ConfigurableFormModule {
  static forFeature(
    settings: ConfigurableFormExternalSettings
  ): ModuleWithProviders<ConfigurableFormModule> {
    return {
      ngModule: ConfigurableFormModule,
      providers: [
        {
          provide: FORMLY_CONFIG,
          multi: true,
          useValue: {
            wrappers: configurableFormWrappers.concat(settings?.wrappers ?? []),
            types: configurableFormTypes.concat(settings?.types ?? []),
            validators: configurableFormValidations.concat(settings?.validators ?? []),
          },
        },
      ],
    };
  }
}
