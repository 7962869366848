import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SvgStaticNames } from '@common-modules/shared/svg/svg-static-names';
import { globalUtilsHelper } from '../shared/helpers/global-utils-helper';

export function isSvgValidator(allowStaticNames = true): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    if (allowStaticNames && Object.values(SvgStaticNames).find((name) => name === value)) {
      return null;
    }

    if (!globalUtilsHelper.isSvgText(value)) {
      return {
        notSvg: true,
      };
    }
    return null;
  };
}
