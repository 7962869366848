import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FilterItemSelectOption } from '@common-modules/common-filters/models/filter-item-select-option';
import { BaseFilterItemSettings } from '@common-modules/dependencies/wlm-filters/base-filter-item-settings';
import { FilterAdapterEnum } from '@common-modules/dependencies/wlm-filters/filter-adapter.enum';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
import { GlobalsService } from '@common-modules/shared/services/globals.service';
import { GisLayerKpiCrudService } from '@water-loss/features/integration/gis-layer-kpi/gis-layer-kpi-crud-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BaseFilterItemComponent } from '../../../core/base-filter-item/base-filter-item.component';
import { BaseSelectFilterItemComponent } from '../../core/base-select-filter-item/base-select-filter-item.component';
import { AdaptedFilterItem } from '../../core/hooks/adapted-filter-item';

const COMPONENT_SELECTOR = 'wlm-network-elements-filter-item';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './network-elements-filter-item.component.html',
  styleUrls: ['./network-elements-filter-item.component.scss'],
  providers: [
    {
      provide: BaseFilterItemComponent,
      useExisting: forwardRef(() => NetworkElementsFilterItemComponent),
    },
  ],
})
export class NetworkElementsFilterItemComponent
  extends BaseSelectFilterItemComponent
  implements OnInit, OnDestroy, AdaptedFilterItem
{
  @Input() defaultSelectedElements: any[];
  @Input() filterListElements: any[];
  @Input() filterSubTypesListElements: any[];
  @Input() filterText: string;
  @Input() gisFlag: boolean = true;
  @Input() isGisEnabled: boolean;
  @Input() isAllNeTypesRequired: boolean;
  // Higher Order Observable.
  data$$ = new BehaviorSubject<Observable<FilterItemSelectOption[]>>(null);

  private defaultSettings = new BaseFilterItemSettings({
    required: true,
  });
  set settings(value: BaseFilterItemSettings) {
    if (value) {
      const settings = Object.assign(this.defaultSettings, value);
      super.settings = Object.assign(super.settings, settings);
    }
  }
  get settings(): BaseFilterItemSettings {
    return super.settings;
  }

  T_SCOPE = `${AppModules.WlmFilters}.${COMPONENT_SELECTOR}`;
  titleKey = `${this.T_SCOPE}.title`;
  inputSummaryKey = `${this.T_SCOPE}.input-summary`;
  @Input() mode: 'single' | 'multiple' = 'multiple';
  @Input() includeOnlyConfigurable = false;
  loaded = false;

  constructor(
    private globalsService: GlobalsService,
    private gisKpiService: GisLayerKpiCrudService,
    private localizationService: LocalizationHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.isGisEnabled) {
      this.data$$.next(this.getDataGis$());
    } else if (this.isAllNeTypesRequired) {
      this.data$$.next(this.getAllData$());
    } else {
      this.data$$.next(this.getData$());
    }
    super.onInit();
  }

  /**
   * Builds an observable that obtains the required data for the select.
   */
  getData$(): Observable<FilterItemSelectOption[]> {
    this.loaded = true;
    return this.globalsService.getNetworkElementTypes(this.includeOnlyConfigurable).pipe(
      switchMap((items) => {
        return this.localizationService.get('common.network-element-types').pipe(
          map((ts) => {
            return items
              .filter(
                (f) =>
                  f.isZone === false &&
                  (!this.filterListElements ||
                    (this.filterListElements &&
                      this.filterListElements.includes(f.networkElementTypeId)))
              )
              ?.sort((a, b) => a.networkElementTypeName.localeCompare(b.networkElementTypeName))
              .map((item) => {
                const label = ts[item.networkElementTypeId] ?? item.networkElementTypeName;
                const option = new FilterItemSelectOption(
                  item.networkElementTypeId,
                  label,
                  item.iconPath,
                  20,
                  20
                );
                return option;
              });
          })
        );
      })
    );
  }

  getAllData$(): Observable<FilterItemSelectOption[]> {
    this.loaded = true;
    return this.globalsService.getNetworkElementTypes(this.includeOnlyConfigurable).pipe(
      switchMap((items) => {
        return this.localizationService.get('common.network-element-types').pipe(
          map((ts) => {
            return items
              .sort((a, b) => a.networkElementTypeName.localeCompare(b.networkElementTypeName))
              .map((item) => {
                const label = ts[item.networkElementTypeId] ?? item.networkElementTypeName;
                const option = new FilterItemSelectOption(
                  item.networkElementTypeId,
                  label,
                  item.iconPath,
                  20,
                  20
                );
                return option;
              });
          })
        );
      })
    );
  }

  getDataGis$(): Observable<FilterItemSelectOption[]> {
    return this.gisKpiService.getConfigurableGisLayers().pipe(
      map((items) => {
        return items.map((item) => {
          return new FilterItemSelectOption(item.gisLayerId, item.description, null, 20, 20);
        });
      })
    );
  }

  getFilterKey(): string {
    return COMPONENT_SELECTOR;
  }

  isValid(): boolean {
    const valid =
      this.isValidSelect &&
      (!this.settings?.required || (this.settings?.required && this.selectedElements?.length > 0));
    return valid;
  }

  getFieldNames(): string[] {
    return [this.fieldName];
  }

  getAdapter(): FilterAdapterEnum {
    return this.settings.id ?? FilterAdapterEnum.ElementTypeFilter;
  }
}
