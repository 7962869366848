import { CommonModule } from '@angular/common';
import { Component, DestroyRef, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subscription, forkJoin, of, take } from 'rxjs';
import { CommonSharedModule } from '../../shared/common-shared.module';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { LocalizationHelperService } from '../../shared/localization/localization-helper.service';
import { isSvgValidator } from '../is-svg.validator';

@Component({
  selector: 'wlm-svg-editor',
  templateUrl: './svg-editor.component.html',
  styleUrls: ['./svg-editor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    TranslateModule,
    CommonSharedModule,
  ],
})
export class SvgEditorComponent implements OnInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() previewLabel: string;
  @Input() previewPlaceholder: string;
  @Input() disabledValidation: boolean;

  private _formControl: FormControl;
  get formControl(): FormControl {
    return this._formControl;
  }
  @Input() set formControl(value: FormControl) {
    this._formControl = value;

    this._valueChangesSubs?.unsubscribe();

    if (this.formControl) {
      if (!this.formControl.hasValidator(isSvgValidator())) {
        this.formControl.addValidators(isSvgValidator());
      }

      this.updatePreview(this.formControl.value);

      this._valueChangesSubs = this.formControl.valueChanges
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe((value) => {
          this.updatePreview(value);
        });
    }
  }

  private _svgContent: ElementRef<HTMLElement>;
  public get svgContent(): ElementRef<HTMLElement> {
    return this._svgContent;
  }
  @ViewChild('svgContent') set svgContent(value: ElementRef<HTMLElement>) {
    this._svgContent = value;
    if (this.svgContent) {
      this._svgContentReady$.next();
      this._svgContentReady$.complete();
    }
  }

  private _svgContentReady$ = new ReplaySubject<void>(1);
  private _valueChangesSubs: Subscription;
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _localization = inject(LocalizationHelperService);
  readonly noPreviewMessageKey = 'common.messages.no-preview';
  previewValue: string;

  constructor() {}

  ngOnInit(): void {}

  private updatePreview(value: string): void {
    this.previewValue = this.sanitizeSvg(value);

    const preview$: Observable<string> = this.isPreviewAvailable()
      ? of(this.previewValue)
      : this._localization.get(this.noPreviewMessageKey).pipe(take(1));

    forkJoin([this._svgContentReady$, preview$]).subscribe(([_, preview]) => {
      if (this.svgContent) {
        this.svgContent.nativeElement.innerHTML = preview;
      }
    });
  }

  private isPreviewAvailable = () =>
    this.previewValue && this.previewValue !== '' && this.formControl.valid;

  private sanitizeSvg = (value: string) => globalUtilsHelper.purifySvg(value);
}
