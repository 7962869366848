import { RepeatComponent } from '@common-modules/shared-component/repeat/repeat.component';
import { TypeOption } from '@ngx-formly/core/lib/models';
import { ChipsInputTypeComponent } from './chips-input-type/chips-input-type.component';
import { ChipsSelectorTypeComponent } from './chips-selector-type/chips-selector-type.component';
import { ColorPickerTypeComponent } from './types/color-picker-type/color-picker-type.component';
import { FileUploadTypeComponent } from './types/file-upload-type/file-upload-type.component';
import { SwitcherTypeComponent } from './types/switcher-type/switcher-type.component';
import { TreeDropdownTypeComponent } from './types/tree-dropdown-type/tree-dropdown-type.component';
export const configurableFormTypes: TypeOption[] = [
  {
    name: 'chips-selector',
    component: ChipsSelectorTypeComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'chips-input',
    component: ChipsInputTypeComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'color-picker',
    component: ColorPickerTypeComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'file-upload',
    component: FileUploadTypeComponent,
  },
  {
    name: 'switcher',
    component: SwitcherTypeComponent,
    wrappers: [],
  },
  {
    name: 'tree-dropdown',
    component: TreeDropdownTypeComponent,
    wrappers: ['form-field'],
  },
  {
    name: 'repeat',
    component: RepeatComponent,
  },
];
