import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationAttributesComponent } from '@water-loss//features/monitoring/notification/notification-components/notification-attributes/notification-attributes.component';
import { CommonFiltersModule } from '../common-filters/common-filters.module';
import { CommonSharedModule } from '../shared/common-shared.module';
import { WidgetRegistryService } from '../widget-registry/widget-registry';
import { WLMGridModule } from '../wlm-grid/wlm-grid.module';
import { ButtonTogglerComponent } from './button-toggler/button-toggler.component';
import { DeletedZonesGridComponent } from './combined-grids/deleted-zones-grid/deleted-zones-grid.component';
import { HierarchyElementSelectionGridComponent } from './combined-grids/hierarchy-elements-selection-grid/hierarchy-elements-selection-grid.component';
import { NeSelectionGridComponent } from './combined-grids/ne-selection-grid/ne-selection-grid.component';
import { EnhancedInputSelectComponent } from './enhanced-input-select/enhanced-input-select.component';
import { NeSelectionGridFilterComponent } from './filters/ne-selection-grid-filter/ne-selection-grid-filter.component';
import { GenericCardComponent } from './generic-card/generic-card.component';
import { HistoricalBarsComponent } from './historical-bars/historical-bars.component';
import { NeSignalSelectionPopupComponent } from './ne-signal-selection/ne-signal-selection-popup/ne-signal-selection-popup.component';
import { NotificationRelatedComponent } from './notification-related/notification-related.component';
import { RangeColorComponent } from './range-color/range-color.component';
import { RowdataVisualizerComponent } from './rowdata-visualizer/rowdata-visualizer.component';

@NgModule({
  declarations: [
    NeSelectionGridComponent,
    HierarchyElementSelectionGridComponent,
    ButtonTogglerComponent,
    RangeColorComponent,
    NeSignalSelectionPopupComponent,
    HistoricalBarsComponent,
    NotificationRelatedComponent,
    NeSelectionGridFilterComponent,
    GenericCardComponent,
    DeletedZonesGridComponent,
    RowdataVisualizerComponent,
  ],
  exports: [
    NeSelectionGridComponent,
    HierarchyElementSelectionGridComponent,
    ButtonTogglerComponent,
    RangeColorComponent,
    NeSignalSelectionPopupComponent,
    HistoricalBarsComponent,
    NotificationRelatedComponent,
    EnhancedInputSelectComponent,
    GenericCardComponent,
    DeletedZonesGridComponent,
    RowdataVisualizerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    WLMGridModule,
    CommonFiltersModule,
    MatButtonToggleModule,
    CommonSharedModule,
    EnhancedInputSelectComponent,
  ],
})
export class CommonSharedComponentModule {
  static injector: Injector;

  constructor(injector: Injector, widgetRegistry: WidgetRegistryService) {
    CommonSharedComponentModule.injector = injector;

    widgetRegistry.register([
      {
        name: 'NotificationRelatedComponent',
        widgetClass: NotificationRelatedComponent,
      },
      {
        name: 'NotificationAttributesComponent',
        widgetClass: NotificationAttributesComponent,
      },
    ]);
  }
}
