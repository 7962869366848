import { Component, inject, Input, OnInit } from '@angular/core';
import { PressureSettings } from '@common-modules/dependencies/ne-configuration/pressure-settings';
import { INetworkElementDto } from '@common-modules/dependencies/ne/network-element.dto';
import { OffsetUnitService } from '../ne-configuration-pressure-historical/offset-unit.service';

const COMPONENT_SELECTOR = 'wlm-ne-configuration-pressure';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-configuration-pressure.component.html',
  styleUrls: ['./ne-configuration-pressure.component.scss'],
})
export class NeConfigurationPressureComponent implements OnInit {
  private _selectedNE: INetworkElementDto;
  get selectedNE(): INetworkElementDto {
    return this._selectedNE;
  }
  @Input() set selectedNE(value: INetworkElementDto) {
    if (value) {
      this._offsetUnitService.buildOffsetUnit(value.hierarchyElementTypeId).subscribe(() => {
        this._selectedNE = value;
      });
    } else {
      this._selectedNE = value;
    }
  }
  @Input() widgetId: string;
  @Input() pageId: string;
  @Input() pressureSettings: PressureSettings;

  pressureSettingsOtp: PressureSettings;

  private readonly _offsetUnitService = inject(OffsetUnitService);

  ngOnInit() {
    this.pressureSettingsOtp = this.pressureSettings;
  }
}
