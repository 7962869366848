// prettier-ignore
import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, inject } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
// prettier-ignore
import { Observable, ReplaySubject, Subject, concat, finalize, map, merge, startWith, switchMap } from 'rxjs';
import { GridSetting } from '../../shared/constants/grid.constants';
import { GridSettingsService } from '../../shared/core/grid/grid-settings.service';
import { DialogService } from '../../shared/dialogs/dialogs.service';
import { GridBtnsEvent } from '../../shared/grid-buttons/models/grid-btns-event';
import { GridBtnsOptions } from '../../shared/grid-buttons/models/grid-btns-options.enum';
import { globalUtilsHelper } from '../../shared/helpers/global-utils-helper';
import { WlmDialogSettings } from '../../shared/model/dialog/wlm-dialog-setting';
import { LogService } from '../../shared/wlm-log/log.service';
import { LocalGridComponent } from '../../wlm-grid/local-grid/local-grid.component';
import { SpinnerService } from '../../wlm-spinner/spinner.service';
import { GenericCrudAdditionalButtonsDirective } from '../directives/generic-crud-additional-buttons.directive';
import { GenericCrudPopupComponent } from '../generic-crud-popup/generic-crud-popup.component';
import { GenericCrudSettings } from '../generic-crud-settings';
import { GenericCrudService } from '../generic-crud.service';

@UntilDestroy()
@Component({
  selector: 'wlm-generic-crud',
  templateUrl: './generic-crud.component.html',
  styleUrls: ['./generic-crud.component.scss'],
})
export class GenericCrudComponent implements OnInit {
  @ViewChild(LocalGridComponent) grid: LocalGridComponent;

  private _settings: GenericCrudSettings;
  get settings(): GenericCrudSettings {
    return this._settings;
  }
  @Input() set settings(value: GenericCrudSettings) {
    this._settings = value;
    this.initFromSettings();
  }
  @Input() filterFn: (items: any[]) => any[];

  @Output() selectedItemChange = new EventEmitter<any>();
  @Output() selectedItemsChange = new EventEmitter<any[]>();
  @Output() entitiesChanged = new EventEmitter<any[]>();

  additionalButtonsTemplates: TemplateRef<GenericCrudAdditionalButtonsDirective>[];
  @ContentChildren(GenericCrudAdditionalButtonsDirective, { read: TemplateRef })
  set queryAdditionalButtonsTemplates(
    value: QueryList<TemplateRef<GenericCrudAdditionalButtonsDirective>>
  ) {
    this.additionalButtonsTemplates = value?.toArray();
  }

  private _selectedItems: any[];
  public get selectedItems(): any[] {
    return this._selectedItems;
  }
  public set selectedItems(value: any[]) {
    this._selectedItems = value;
    this.selectedItemsChange.emit(this._selectedItems);
  }

  selectedItem: any;
  private _filters: any;
  readonly clearAll$ = new Subject<void>();

  private readonly _gridSettingsService = inject(GridSettingsService);
  private readonly _dialogService = inject(DialogService);
  private readonly _log = inject(LogService);
  private readonly _spinnerService = inject(SpinnerService);

  private _service: GenericCrudService<any, any>;
  private _settingsReady$ = new ReplaySubject<void>(1);
  private _refreshItems$ = new Subject<void>();
  private _gridReady$ = new ReplaySubject<void>(1);

  private setLoading: (loading: boolean) => void;

  gridSettings: GridSetting = null;
  readonly gridSettings$: Observable<GridSetting> = this._settingsReady$.pipe(
    switchMap(() =>
      this.wrapInSpinner(
        this._gridSettingsService.getGridSettingsByName(this.settings.grid.gridSettingsName)
      )
    ),
    startWith(null)
  );

  readonly allEntities$ = concat(
    this._gridReady$,
    merge(this._settingsReady$, this._refreshItems$).pipe(
      switchMap(() => this.wrapInSpinner(this._service.getAll(this._filters))),
      map((items) => (this.filterFn ? this.filterFn(items) : items)),
      startWith([])
    )
  );

  constructor() {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {
    this.gridSettings$.pipe(untilDestroyed(this)).subscribe((gridSettings) => {
      this.gridSettings = gridSettings;
    });
  }

  private initFromSettings(): void {
    if (this.settings) {
      this._service = this.settings.injector.get(this.settings.service);
      this._settingsReady$.next();
      this._settingsReady$.complete();
    }
  }

  onGridReady(): void {
    this._gridReady$.next();
    this._gridReady$.complete();
  }

  onOpenCreate(event): void {
    this.onOpenPopup('create');
  }

  onOpenUpdate(event): void {
    this.onOpenPopup('update');
  }

  onDeleteConfirm(event): void {
    if (this._settings.delete) {
      const confirmMessageKey = this.getConfirmDeleteMessageKey();
      if (confirmMessageKey) {
        this._dialogService
          .showTranslatedDialogMessage({
            translateKey: confirmMessageKey,
            icon: 'warning',
          })
          .subscribe((result) => {
            if (result.result) {
              this.deleteSelectedItem();
            }
          });
      } else {
        this.deleteSelectedItem();
      }
    }
  }

  private deleteSelectedItem(): void {
    if (this.selectedItem) {
      this.setLoading(true);
      const id = this._service.getId(this.selectedItem);
      this._service
        .delete(id)
        .pipe(finalize(() => this.setLoading(false)))
        .subscribe({
          next: (result) => {
            this.showSuccess();
            this.refreshItems();
          },
          error: this._dialogService.showErrorMessage,
        });
    }
  }

  onSelectedItem(selectedItem): void {
    this.selectedItem = selectedItem;
    this.selectedItemChange.emit(this.selectedItem);
  }

  private onOpenPopup(op: 'create' | 'update'): void {
    const popupSettings = op === 'create' ? this._settings.create : this._settings.update;
    const dialogConfig = popupSettings.dialogConfig ?? new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      op,
      popupSettings,
      settings: globalUtilsHelper.clone(this._settings, false),
      service: this._service,
      selectedItem: op === 'update' ? this.selectedItem : null,
      filters: this._filters,
    };

    const dialogRef = this._dialogService.openComponentDefault(
      GenericCrudPopupComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((payload) => {
      if (payload) {
        this.refreshItems();
      }
    });
  }

  refreshItems(): void {
    this._refreshItems$.next();
    this.selectedItem = null;
  }

  applyExternalFilters(filters): void {
    this._filters = filters;
    this.refreshItems();
  }

  private wrapInSpinner(query$: Observable<any>): Observable<any> {
    this.setLoading(true);
    return query$.pipe(finalize(() => this.setLoading(false)));
  }

  onClickGridBtns(events: GridBtnsEvent): void {
    switch (events.btn) {
      case GridBtnsOptions.ClearGridFilters:
        this.onClearGridFilters();
        return;
    }

    this._log.error({ msg: 'The clicked grid button does not have a handler yet' });
  }

  onClearGridFilters(): void {
    if (this.selectedItems?.length) {
      this.selectedItems = [];
      this.selectedItemsChange.emit(this.selectedItems);
    }
    this.grid.clearFilters();
  }

  private showSuccess(): void {
    this._dialogService.showTranslatedMessageInSnackBar(
      new WlmDialogSettings({
        translateKey: 'common.messages.success',
        icon: 'success',
      })
    );
  }

  private getConfirmDeleteMessageKey(): string {
    return this.settings.delete?.confirmMessageKey ?? 'common.messages.delete-confirm-generic';
  }
}
