import { Component, Injector, OnInit, inject } from '@angular/core';
import { INetworkElementSubTypeDto } from '@common-modules/dependencies/ne/network-element-subtype.dto';
import { GenericCrudSettings } from '@common-modules/generic-crud/generic-crud-settings';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { ValidationHelperService } from '@common-modules/shared/helpers/validation-helper.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { Observable, map } from 'rxjs';
import { NeSubTypeFormComponent } from '../ne-subtype-form/ne-subtype-form.component';
import { NESubTypesCrudService } from '../ne-subtypes-crud.service';

const COMPONENT_SELECTOR = 'wlm-ne-subtype-crud';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './ne-subtype-crud.component.html',
  styleUrl: './ne-subtype-crud.component.scss',
})
export class NeSubTypeCrudComponent implements OnInit {
  settings: GenericCrudSettings;
  private readonly _injector = inject(Injector);
  private readonly _neSubTypesCrudService = inject(NESubTypesCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  private readonly _spinnerService = inject(SpinnerService);
  private readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  ngOnInit(): void {
    this.buildSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'NESubTypesCrudService',
      grid: {
        gridSettingsName: 'NetworkElementSubTypesCrud',
      },
      create: {
        formComponent: NeSubTypeFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: NeSubTypeFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(false),
      },
      delete: {},
    });
  }

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (model: INetworkElementSubTypeDto): Observable<INetworkElementSubTypeDto | null> => {
      return this._neSubTypesCrudService.validate(model, isCreate).pipe(
        map((validation) => {
          if (validation.isDuplicatedId) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.network-element-sub-type-id`
            );
            return null;
          }
          return this.sanitizeModel(model);
        })
      );
    };

  private sanitizeModel(model: INetworkElementSubTypeDto): INetworkElementSubTypeDto {
    if (model?.iconPath) {
      model.iconPath = globalUtilsHelper.purifySvg(model.iconPath);
    }
    return model;
  }
}
