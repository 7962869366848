import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CommonSharedModule } from '@common-modules/shared/common-shared.module';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wlm-repeat',
  templateUrl: './repeat.component.html',
  styleUrls: ['./repeat.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    FormlyModule,
    CommonModule,
    CommonSharedModule,
    MatButtonToggleModule,
  ],
})
export class RepeatComponent extends FieldArrayType {
  @Input() addDefaultRecord: boolean;

  ngOnInit() {
    if (this.props.addDefaultRecord) {
      this.add();
    }
  }
}
