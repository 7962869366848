// prettier-ignore
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { IExportExcelComponent } from '@common-modules/shared/exports/models/export-excel-component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as echarts from 'echarts';
import { ECharts, EChartsOption } from 'echarts';
import { ZRColor } from 'echarts/types/dist/shared';
import { asEnumerable } from 'linq-es2015';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
// prettier-ignore
import { ExportExcelColumnSettings, ExportExcelSettings } from '@common-modules/shared/exports/models/export-excel-settings';
import { IExportPdfComponent } from '@common-modules/shared/exports/models/export-pdf-component';
import {
  ExportPdfDocument,
  PdfExportItem,
} from '@common-modules/shared/exports/models/export-pdf-document';
import { GenericExportService } from '@common-modules/shared/exports/service/generic-export.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { DateFormats } from '@common-modules/shared/localization/date-formats.enum';
import { LocalizationHelperService } from '@common-modules/shared/localization/localization-helper.service';
// prettier-ignore
import { WlmResizeObserverData, WlmResizeObserverService } from '@common-modules/shared/services/resize-observer.service';
import { LogService } from '@common-modules/shared/wlm-log/log.service';
import { chartMapLoader } from '../data/chart-map-loader';
import { ChartCustomHook } from '../models/chart-custom-hook';
import { ChartCustomHookNames } from '../models/chart-custom-hook-names';
import { GChartLegend } from '../models/generic-chart-settings/g-chart-legend';
import { GenericCartesianChartSettings } from '../models/generic-chart-settings/generic-cartesian-chart-settings';
import { GenericCartesianCustomChartSettings } from '../models/generic-chart-settings/generic-cartesian-custom-chart-settings';
import { GenericPieChartSettings } from '../models/generic-chart-settings/generic-pie-chart-settings';
import {
  GChartBrushArea,
  GChartBrushEndEvent,
} from '../models/generic-events/g-chart-brush-end-event';
import { GChartClickEvent } from '../models/generic-events/g-chart-click-event';
// prettier-ignore
import { IElementSize } from '@common-modules/shared/model/element-size';
import { CustomThemes } from '@common-modules/shared/theme/custom-themes';
import { ThemeService } from '@common-modules/shared/theme/theme.service';
import { GCartesianChartSerie } from '../models/generic-chart-settings/g-cartesian-chart-series';
import { GenericSeries } from '../models/generic-chart-settings/generic-series';
import { GenericChartUnselectedSeries } from '../models/generic-chart-unselected-series';
import {
  GChartDataZoomEvent,
  GChartDataZoomItemEvent,
} from '../models/generic-events/g-chart-data-zoom-event';
import { GChartInitEvent } from '../models/generic-events/g-chart-init-event';
import { GChartLegendSelectedEvent } from '../models/generic-events/g-chart-legend-selected-event';
import { EchartsSettingsMapperService } from '../services/echarts-settings-mapper.service';
import { GenericChartService } from '../services/generic-chart.service';

// Necessary for "geo" property to work.
chartMapLoader(echarts);

const COMPONENT_SELECTOR = 'wlm-generic-chart';

@UntilDestroy()
@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './generic-chart.component.html',
  styleUrls: ['./generic-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericChartComponent
  implements OnInit, OnDestroy, IExportExcelComponent, IExportPdfComponent
{
  T_SCOPE = `${AppModules.WlmCharts}.${COMPONENT_SELECTOR}`;

  @Input() set settings(
    value:
      | GenericCartesianChartSettings
      | GenericCartesianCustomChartSettings
      | GenericPieChartSettings
  ) {
    this._settings = value;
    this.nativeSettings = this._mapper.mapSettings(this._settings);
    if (
      this.nativeSettings &&
      this.nativeSettings.series &&
      Array.isArray(this.nativeSettings.series)
    ) {
      this.currentNativeSeries = [...(this.nativeSettings.series as any[])];
    }
    // This is to detect when a chart that already had settings receives new settings.
    // We consider this as initializing the chart again, but ECharts will not throw the chartInit event again.
    if (this._chartInstance) {
      this.onChartInit(this._chartInstance);
    }

    if (this._chartInstanceExport) {
      this.onChartInitExport(this._chartInstanceExport);
    }

    this.chartLoaded.emit(true);
  }

  private _listenResizeElement: HTMLElement;
  get listenResizeElement(): HTMLElement {
    return this._listenResizeElement;
  }
  @Input() set listenResizeElement(value: HTMLElement) {
    this._listenResizeElement = value;
    if (this.listenResizeElement) {
      this.listenResize();
    }
  }

  // If a specified size is setted, ignore listeing to resize

  private _size: IElementSize;
  get size(): IElementSize {
    return this._size;
  }
  @Input() set size(value: IElementSize) {
    this._size = value;
    if (this.size) {
      this.disableFixedSize = true;
      this.resize(this.size);
    }
  }

  @Input() hooks: ChartCustomHook[];
  @Input() loadingHandler$ = new BehaviorSubject<boolean>(false);
  @Input() exportChart$: Observable<void>;
  @Input() showDataPoints$: Observable<boolean>;
  @Input() disableFixedSize = true;
  @Input() genericChartService: GenericChartService;
  @Input() chartDataZoomDebounceTime = 250;
  @Input() height: number;
  @Output() chartInitEvent = new EventEmitter<GChartInitEvent>();
  @Output() chartClickEvent = new EventEmitter<GChartClickEvent>();
  @Output() chartDataZoomEvent = new EventEmitter<GChartDataZoomEvent>();
  @Output() chartLegendSelectedEvent = new EventEmitter<GChartLegendSelectedEvent>();
  @Output() chartLoaded = new EventEmitter<boolean>();
  @Output() chartFinished = new EventEmitter<void>();
  @Output() chartRendered = new EventEmitter<void>();
  @Output() chartBrushEnd = new EventEmitter<GChartBrushEndEvent>();
  @Output() updatedDataPoints = new EventEmitter<Map<string, any>>();
  // Series that have been unselected by clicking on the legend
  @Output() serieNamesUnselected = new EventEmitter<GenericChartUnselectedSeries>();

  //This clickEvent is fired when the click is made outside of any chart element
  @Output() clickEvent = new EventEmitter<any>();

  initSettings;
  chartDataZoomDebouncer$ = new Subject<GChartDataZoomEvent>();

  nativeSettings: EChartsOption;
  loading = false;
  mergeSettings: EChartsOption = null;
  currentNativeSeries: any[] = [];
  enableExportChart = false;
  chartObjectToExport$ = new Subject<string>();
  chartTheme: string | null;
  defaultChartHeight = 400;

  private _getChartObjectOnly = false;
  private _settings:
    | GenericCartesianChartSettings
    | GenericCartesianCustomChartSettings
    | GenericPieChartSettings;
  private _chartInstance: ECharts;
  private _chartInstanceExport: ECharts;
  private _chartInstanceSubscription: Subscription;
  private _chartInstance$ = new ReplaySubject<any>(1);
  readonly chartInstance$ = this._chartInstance$.asObservable();
  private _resize$ = new Subject();
  private _resizeSubs: Subscription;
  private _exportEnqueued = false;
  private _defaultTimeout = 500;
  private readonly _chartMinHeight = 250;
  private readonly _scrollReservedSpace = 8;

  private readonly _millisecondsInADay = 86400000;
  private readonly _genericFilename = 'chart';
  private readonly _pdfImageXCoords = 60;
  private readonly _pdfDocumentHeight = 120;
  private readonly _pdfDocumentWidth = 190;
  private readonly _pngDocumentWidth = 1900;

  constructor(
    private _mapper: EchartsSettingsMapperService,
    private _localizationService: LocalizationHelperService,
    private _log: LogService,
    private _resizeObserverService: WlmResizeObserverService,
    private _genericExportService: GenericExportService,
    private _cd: ChangeDetectorRef,
    private readonly _themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.initSettings = {
      locale: this._localizationService.currentLocale,
    };

    if (this.loadingHandler$) {
      this.loadingHandler$
        .pipe(untilDestroyed(this))
        .subscribe((isLoading) => (this.loading = isLoading));
    }

    if (this.exportChart$) {
      this.exportChart$.pipe(untilDestroyed(this)).subscribe(() => this.exportChart());
    }

    this.showDataPoints$
      ?.pipe(untilDestroyed(this))
      .subscribe((show) => this.showHideDataPoints(show));

    // Debounce the DZ event to decrease overload.
    this.chartDataZoomDebouncer$
      .pipe(debounceTime(this.chartDataZoomDebounceTime), untilDestroyed(this))
      .subscribe((event) => {
        const processed = this.processDataZoomEvent(event);
        this.chartDataZoomEvent.emit(processed);
      });

    this._themeService.theme$.pipe(untilDestroyed(this)).subscribe((theme) => {
      this.chartTheme = theme == CustomThemes.Dark ? 'w-dark' : 'w-white';
      this._cd.detectChanges();
    });
  }

  onChartInit(instance) {
    this._chartInstance$ = new ReplaySubject<any>(1);
    this._chartInstance = instance;
    this._chartInstance$.next(this._chartInstance);
    if (this.size) {
      this.resize(this.size);
    }

    this.chartInitEvent.next(
      new GChartInitEvent({
        nativeSeries: this.currentNativeSeries,
        mergeOptionsFn: this.mergeOptionsFn,
      })
    );
    this.applyCustomHook(ChartCustomHookNames.ChartInit, null);

    this._chartInstance.getZr().on('click', (event) => {
      this.clickEvent.emit(event);
    });
  }

  onChartInitExport(instance) {
    this._chartInstanceExport = instance;
  }

  onChartRenderedExport(event): void {
    if (!this._exportEnqueued) {
      this._exportEnqueued = true;

      setTimeout(() => {
        if (this._getChartObjectOnly) {
          const opts: any = this.getBaseExportChartOptions();
          this.changeLegendType('plain');

          const imgData: any = this._chartInstanceExport.getConnectedDataURL(opts as any);
          this.changeLegendType('scroll');

          this.chartObjectToExport$.next(imgData);
          this._getChartObjectOnly = false;
        } else {
          this.generatePdfFromChart();
        }

        this.loading = false;
        this.enableExportChart = false;
        this._exportEnqueued = false;
        this._cd.detectChanges();
      }, this._defaultTimeout * 2);
    }
  }

  resize(resizeOpts?: { width: number | 'auto'; height: number | 'auto' }): void {
    try {
      if (resizeOpts) {
        resizeOpts.height = Math.max(Number(resizeOpts.height), this._chartMinHeight);
        resizeOpts.width = Number(resizeOpts.width) - this._scrollReservedSpace;
      }
      this._chartInstance?.resize(resizeOpts);
    } catch (error) {
      this._log.warn({ msg: '', payload: error });
    }
  }

  private listenResize(): void {
    if (!this.listenResizeElement || this.size) {
      return;
    }

    this._resizeSubs?.unsubscribe();

    const initialSize = globalUtilsHelper.getElementSize(this.listenResizeElement);
    this.resize(initialSize);

    this._resizeSubs = this._resizeObserverService
      .observe({
        el: this.listenResizeElement,
        debounce: 200,
      })
      .subscribe((data: WlmResizeObserverData) => {
        if (this.size) {
          // If a specified size is setted, ignore listening to resize.
          return;
        }
        if (data?.native[0] && data.native[0][0] && data.native[0][0].contentRect) {
          const rect = data.native[0][0].contentRect;
          const newSize = {
            width: rect.width,
            height: rect.height,
          };
          this.resize(newSize);
          this._resize$.next(newSize);
        }
      });
  }

  setZoomBar(showZoom: boolean) {}

  getNativeSettings(): EChartsOption {
    return this.nativeSettings;
  }

  getResizeObserver(): Observable<any> {
    return this._resize$.asObservable();
  }

  getCurryFnHelper = echarts.util.curry;

  /**
   * Process data and dimensionNames into an object.
   */
  onChartClick = (event) => {
    const processed: GChartClickEvent = {
      data: {},
      nativeEvent: event,
    };

    try {
      if (event?.data) {
        const valuesArray = Array.isArray(event.data) ? event.data : event.data.value;
        valuesArray.forEach((item, index) => {
          const dim = event.dimensionNames[index];
          processed.data[dim] = item;
        });

        const extraProperties = event.data.extraProperties;
        if (extraProperties) {
          processed.data['extraProperties'] = extraProperties;
        }
      }
    } catch (error) {}

    this.chartClickEvent.emit(processed);
    this.applyCustomHook(ChartCustomHookNames.ChartClick, event);
  };

  onChartDataZoom(event): void {
    this.chartDataZoomDebouncer$.next(event);
    this.applyCustomHook(ChartCustomHookNames.ChartDataZoom, event);
  }

  onChartLegendSelectChanged(event): void {
    this.chartLegendSelectedEvent.emit(
      new GChartLegendSelectedEvent({
        event,
        nativeSeries: this.currentNativeSeries,
        mergeOptionsFn: this.mergeOptionsFn,
      })
    );
    this.applyCustomHook(ChartCustomHookNames.ChartLegendSelectChanged, event);

    const unselectedSerieNames = [];

    Object.keys(event.selected).forEach((key) => {
      if (!event.selected[key]) {
        unselectedSerieNames.push(key);
      }
    });

    const unselectedSeries = (this._settings.series as any[]).filter((serie) =>
      unselectedSerieNames.find((serieName) => serieName === serie.name)
    );

    const unselectedData: GenericChartUnselectedSeries = {
      names: unselectedSerieNames,
      series: unselectedSeries,
    };

    this.serieNamesUnselected.emit(unselectedData);
    this.genericChartService?.setVisibleSeriesByName(unselectedSerieNames);
  }

  onChartFinished(event): void {
    this.chartFinished.emit(event);
    this.applyCustomHook(ChartCustomHookNames.ChartFinished, event);
  }

  onChartRendered(event): void {
    this.chartRendered.emit(event);
  }

  onChartBrushEnd(event) {
    const processed: GChartBrushEndEvent = {
      areas: [],
      nativeEvent: event,
    };

    if (Array.isArray(event?.areas)) {
      [...event.areas].forEach((a) => {
        const area: GChartBrushArea = {
          brushType: a.brushType,
          range: a.coordRange,
        };

        processed.areas.push(area);
      });
    }

    this.chartBrushEnd.emit(processed);
  }

  mergeOptionsFn = (mergeSettings) => {
    setTimeout(() => {
      this.chartLoaded.emit(true);
      this._chartInstance.setOption(mergeSettings, {
        replaceMerge: 'series', // TODO: Customize this.
      });
      if (mergeSettings && mergeSettings.series && Array.isArray(mergeSettings.series)) {
        this.currentNativeSeries = [...mergeSettings.series];
      }
    }, this._defaultTimeout);
  };

  /**
   * Search if the user has defined any hook for an event name, and apply it in that case.
   */
  applyCustomHook(eventName: ChartCustomHookNames, event): void {
    if (this.hooks) {
      const selectedHook = this.hooks.find((h) => h.eventName === eventName);
      if (selectedHook) {
        selectedHook.hook(event, this.nativeSettings, this._chartInstance);
      }
    }
  }

  getCurrentDataZooms(): { [key: string]: GChartDataZoomItemEvent } {
    // Add a hash of all the items, for more complex cases.
    const items: { [key: string]: GChartDataZoomItemEvent } = {};
    // For some reason, the inner settings of the chart has more datazoom info than the event.
    const settings = this.getChartInstance()?.getOption();

    if (!settings) {
      return null;
    }

    (settings.dataZoom as any)?.forEach((dataZoomItem) => {
      items[dataZoomItem.id] = this.processDataZoomItemEvent(dataZoomItem);
    });

    return items;
  }

  processDataZoomEvent = (event): GChartDataZoomEvent => {
    // Keep initial behavior, to avoid breaking code.
    const result = new GChartDataZoomEvent(this.processDataZoomItemEvent(event));
    result.items = this.getCurrentDataZooms();

    return result;
  };

  private processDataZoomItemEvent(dataZoomItem): GChartDataZoomItemEvent {
    return dataZoomItem as GChartDataZoomItemEvent;
  }

  private formatDate = (format: DateFormats) => {
    return (date: Date) => this._localizationService.formatDate(date, format);
  };

  private setAxisFormatter(start: number, end: number) {
    const xAxis = [...(this.nativeSettings.xAxis as Array<any>)];
    const axisDuration = xAxis[0].max.valueOf() - xAxis[0].min.valueOf();
    const percentageSelected = Math.abs(end - start) / 100;

    const dateFormat =
      this._millisecondsInADay < percentageSelected * axisDuration
        ? DateFormats.Date
        : DateFormats.DateTime;

    xAxis[0].axisLabel.formatter = this.formatDate(dateFormat);
    setTimeout(() => {
      this._chartInstance.setOption(
        { xAxis },
        {
          replaceMerge: 'xAxis',
        }
      );
    }, this._defaultTimeout);
  }

  /**
   * Allows to update the series of a currently instantiated chart.
   */
  updateSeries(newSeries: GenericSeries, updateAll = true): Observable<void> {
    this._settings.series = newSeries;
    const nativeSettings = this._mapper.mapSettings(this._settings);

    const newSettings = updateAll ? nativeSettings : { series: nativeSettings.series };

    this._chartInstanceSubscription?.unsubscribe();
    this._chartInstanceSubscription = this._chartInstance$
      .pipe(untilDestroyed(this))
      .subscribe((chartInstance) => {
        setTimeout(() => {
          chartInstance.setOption(newSettings, {
            replaceMerge: 'series',
          });
        }, this._defaultTimeout);
      });

    return of(null);
  }

  ngOnDestroy(): void {
    if (this._chartInstance && !this._chartInstance.isDisposed()) {
      this._chartInstance.dispose();
    }
  }

  exportToPdf(): void {
    this.enableExportChart = true;
    this.loading = true;
    this._cd.detectChanges();
  }

  generatePdfFromChart() {
    const opts = this.getBaseExportChartOptions();
    this.changeLegendType('plain');

    const imgData = this._chartInstanceExport.getConnectedDataURL(opts as any);

    const documentItem = new PdfExportItem({
      data: imgData,
      type: 'image',
      xCoords: this._pdfImageXCoords,
    });

    this.changeLegendType('scroll');

    const fileName = this._settings.exportFileName
      ? `${this._settings.exportFileName}.pdf`
      : `${this._genericFilename}.pdf`;

    const document = new ExportPdfDocument({
      filename: fileName,
      title: this._settings.exportFileName,
      items: [documentItem],
      height: this._pdfDocumentHeight,
      width: this._pdfDocumentWidth,
      unit: 'mm',
      orientation: 'landscape',
    });

    this._genericExportService.exportPdfDocument(document);
  }

  isEmpty(): boolean {
    return (this.settings as any)?.series?.length === 0;
  }

  getObjectToExport(): string {
    return null;
  }

  getObjectToExportObservable(): Observable<string> {
    this.enableExportChart = true;
    this.loading = true;
    this._getChartObjectOnly = true;
    this._cd.detectChanges();

    return this.chartObjectToExport$.asObservable();
  }

  private getBaseExportChartOptions() {
    return {
      type: 'png',
      // Resolution ratio of exporting image, 1 by default.
      pixelRatio: 3,
      // Background color of exporting image, use backgroundColor in option by default.
      backgroundColor: '#FFFFFF',
      // Excluded components list. e.g. ['toolbox']
      excludeComponents: ['dataZoom', 'toolbox'],
    } as {
      type?: 'png' | 'jpg' | 'svg';
      pixelRatio?: number;
      backgroundColor?: ZRColor;
      connectedBackgroundColor?: ZRColor;
      excludeComponents?: string[];
    };
  }

  exportChart(): void {
    const opts = this.getBaseExportChartOptions();
    this.setExportOptions();

    const url = this._chartInstance.getConnectedDataURL(opts as any);
    globalUtilsHelper.resizeImage(
      url,
      this._pngDocumentWidth,
      this._settings.exportFileName,
      (dataUrl, fileName) => {
        globalUtilsHelper.exportURL(
          dataUrl,
          fileName ? `${fileName}.png` : `${this._genericFilename}.png`
        );

        this.setOriginalOptions();
      }
    );
  }

  getSettings = () => this._settings;
  getChartInstance = () => this._chartInstance;

  getExportExcelSettings(): Observable<ExportExcelSettings> {
    return this._localizationService.get(`${this.T_SCOPE}.export-columns`).pipe(
      map((locales) => {
        const settings = this.getSettings() as GenericCartesianChartSettings;

        let columns = [];
        const customColumnsFormated = [];
        const customCols = settings.columnCustomizations;
        if (customCols) {
          customCols.forEach((customCol) => {
            customColumnsFormated.push(
              new ExportExcelColumnSettings({
                fieldName: customCol.fieldName,
                title: locales[customCol.titleKey],
                format: customCol?.format ? customCol.format() : undefined,
              })
            );
          });
        }

        const columnsBase = [
          new ExportExcelColumnSettings({
            fieldName: 'serieName',
            title: locales['serie'],
          }),
          new ExportExcelColumnSettings({
            fieldName: 'pointCategory',
            title: locales['date'],
            format: this._localizationService.getDateTimeFormat(),
          }),
          new ExportExcelColumnSettings({
            fieldName: 'pointValue',
            title: locales['value'],
          }),
          new ExportExcelColumnSettings({
            fieldName: 'unit',
            title: locales['unit'],
          }),
        ];

        if (!customColumnsFormated.length) {
          columns = columnsBase;
        } else {
          columnsBase.forEach((columnBase) => {
            const customCol = customColumnsFormated.find(
              (x) => x.fieldName === columnBase.fieldName
            );

            columns.push(customCol ? customCol : columnBase);
          });
        }

        const data = asEnumerable(settings.series)
          .SelectMany((serie) => {
            return serie.dataPoints.map((dataPoint) => {
              return {
                serieName: serie.name,
                pointValue: dataPoint.pointValue,
                pointCategory: dataPoint.pointCategory,
                unit: this.getSerieUnitLabel(serie, settings),
              };
            });
          })
          .ToArray();

        const excelSettings = new ExportExcelSettings({
          columns,
          data,
          fileName: settings.exportFileName,
        });

        return excelSettings;
      })
    );
  }

  private changeLegendType(type: 'plain' | 'scroll', top: 'bottom' | 'top' = 'bottom'): void {
    const currentLegend = this._chartInstanceExport.getOption().legend as GChartLegend;

    let newLegend = { ...currentLegend, type: type, top: top };
    this._chartInstanceExport.setOption({
      legend: newLegend,
      lazyUpdate: false,
    });
  }

  private setExportOptions() {
    const nativeSettings = this._mapper.mapSettings(this._settings);
    nativeSettings.legend['type'] = 'plain';
    nativeSettings.dataZoom = [];
    nativeSettings.legend['bottom'] = '50';

    setTimeout(() => {
      this._chartInstance.setOption(nativeSettings, {
        replaceMerge: ['legend', 'dataZoom'],
      });
    }, this._defaultTimeout);
  }

  private setOriginalOptions() {
    const nativeSettings = this._mapper.mapSettings(this.getSettings());
    setTimeout(() => {
      this.getChartInstance().setOption(nativeSettings);
    }, this._defaultTimeout);
  }

  showHideDataPoints(show: boolean) {
    const nativeSettings = this._mapper.mapSettings(this._settings);
    const series = nativeSettings.series as any[];
    series.forEach((serie) => {
      const serieData = serie.data as any[];
      serieData.forEach((dataPoint) => {
        dataPoint.symbol =
          (!dataPoint.largeNumberOfPoints || dataPoint.hasToShowSymbol) && show
            ? undefined
            : !dataPoint.alwaysShowSymbol
            ? 'none'
            : undefined;
      });
    });

    nativeSettings.series = series;

    setTimeout(() => {
      this.getChartInstance().setOption(
        { series: nativeSettings.series },
        { replaceMerge: ['series'] }
      );
    }, this._defaultTimeout);
  }

  private getSerieUnitLabel(
    serie: GCartesianChartSerie,
    settings: GenericCartesianChartSettings
  ): string {
    if (serie.yAxisName) {
      return serie.yAxisName;
    }
    if (serie.yAxisIndex !== null && typeof serie.yAxisIndex !== 'undefined' && settings.yAxes) {
      return settings.yAxes[serie.yAxisIndex]?.name;
    }
    return null;
  }
}
