import { Injectable, inject } from '@angular/core';
import { BaseService } from '@common-modules/shared/base.service';
import { SettingsService } from '@common-modules/shared/config/settings.service';
import { GridSetting } from '@common-modules/shared/constants/grid.constants';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { StringHelperService } from '@common-modules/shared/helpers/string-helper.service';
import { load } from '@loaders.gl/core';
import { _GeoJSONLoader } from '@loaders.gl/json';
import { Observable, ReplaySubject, forkJoin, from, map, startWith, switchMap, take } from 'rxjs';
import * as shapefile from 'shapefile';
import { GisElementDefinitionTypesDto } from './gis-element-definition-types.dto';
import { IntegrationGisLayersDto } from './integration-gis-layers.dto';

const gridSettingBase: GridSetting = {
  count: true,
  filterable: 'menu',
  navigatable: false,
  pageSize: 20,
  ignoreCase: true,
  pageable: {
    type: 'input',
    pageSizes: [10, 20, 50],
  },
  reorderable: true,
  resizable: true,
  selectable: {
    enabled: false,
    checkboxOnly: true,
  },
  showSelectAllColumn: false,
  showZoneColumns: false,
  sortable: true,
  gridColumnSettings: [],
  buttons: { show: ['clear_grid_filters'] },
  excelFileName: '',
  selectionPersistency: false,
  hideHeader: false,
  utcDates: false,
};

type TElement = { [key: string]: any };
type TField = { field: string; fieldNormalized: string; title: string; isNumeric: boolean };
const separator = '_____';
const typeNumber = typeof Number;

@Injectable()
export class IntegrationGisLayersService extends BaseService {
  private readonly _stringHelperService = inject(StringHelperService);
  private readonly _settingsService = inject(SettingsService);
  private readonly _elements$ = new ReplaySubject<TElement[]>(1);
  private readonly _gridSettings$ = new ReplaySubject<GridSetting>(1);
  private readonly _layer$ = new ReplaySubject<number>(1);
  private readonly _columns$ = new ReplaySubject<TField[]>(1);
  private readonly _elementTypeId$ = new ReplaySubject<GisElementDefinitionTypesDto>(1);

  private readonly _geojsonFeatures$ = new ReplaySubject<GeoJSON.FeatureCollection>(1);
  private readonly _isMappingsFormValid$ = new ReplaySubject<boolean>(1);
  private readonly _resetAll$ = new ReplaySubject<void>(1);
  readonly dataLoaded$ = this._geojsonFeatures$.asObservable().pipe(map((data) => !!data));
  readonly isMappingsFormValid$ = this._isMappingsFormValid$.asObservable().pipe(startWith(false));
  readonly resetAll$ = this._resetAll$.asObservable();
  private _geojsonFeatures: GeoJSON.FeatureCollection;
  private _elementTypeId: GisElementDefinitionTypesDto;
  private _propertyNames: string[] = [];
  private readonly _gisIdProperty = 'gis_id';

  upload(data: IntegrationGisLayersDto): Observable<boolean> {
    const isShapeFile = (file: any) => {
      if (Array.isArray(file)) {
        return file.some((f) => f.name.endsWith('.shp') || f.name.endsWith('.dbf'));
      }
      return file.name.endsWith('.shp') || file.name.endsWith('.dbf');
    };

    if (isShapeFile(data.layersFile)) {
      const observable$ = from(
        this.loadShapeFile(Array.isArray(data.layersFile) ? data.layersFile : [data.layersFile])
      );
      return observable$.pipe(
        map((geoJson) =>
          this.load(
            geoJson,
            data.networkElementTypeId,
            data.gisLayerId,
            data.networkElementSubTypeId
          )
        )
      );
    } else {
      const observable$ = from(this.loadJson(data.layersFile));
      return observable$.pipe(
        map((geoJson) =>
          this.load(
            geoJson,
            data.networkElementTypeId,
            data.gisLayerId,
            data.networkElementSubTypeId
          )
        )
      );
    }
  }

  load(
    geoJson,
    networkElementTypeId: number,
    gisLayerId: number,
    networkElementSubTypeId?: number
  ): boolean {
    const validationErrors = this.validate(geoJson);
    const isValid = validationErrors.length == 0;

    if (!isValid) {
      throw new Error(validationErrors.join(', '));
    }

    this._geojsonFeatures = geoJson as GeoJSON.FeatureCollection;
    const elements = this.getTable(this._geojsonFeatures);
    const columns = this.getTableColumns(elements);
    const gridSettings = this.getTableGridSettings(columns);
    const neDefinitionTypes = new GisElementDefinitionTypesDto({
      networkElementTypeId,
      gisLayerId,
      networkElementSubTypeId,
    });
    this._elementTypeId = neDefinitionTypes;
    this._elementTypeId$.next(neDefinitionTypes);
    this._propertyNames = this.calculatePropertyNames();
    this._gridSettings$.next(gridSettings);
    this._elements$.next(elements);
    this._layer$.next(gisLayerId);
    this._columns$.next(columns);
    this._geojsonFeatures$.next(this._geojsonFeatures);

    return isValid;
  }

  initialLoad(): void {}

  getElements(): Observable<TElement[]> {
    return this._elements$.asObservable().pipe(startWith([]));
  }

  getGridSettings(): Observable<GridSetting> {
    return this._gridSettings$.asObservable().pipe(startWith(gridSettingBase));
  }

  getLayer(): Observable<number> {
    return this._layer$.asObservable().pipe(startWith(0));
  }

  getFields(): Observable<TField[]> {
    return this._columns$.asObservable().pipe(startWith([]));
  }

  getElementTypeId(): Observable<GisElementDefinitionTypesDto> {
    return this._elementTypeId$.asObservable().pipe(startWith(null));
  }

  getGeojsonFeatures(): Observable<GeoJSON.FeatureCollection> {
    return this._geojsonFeatures$.asObservable().pipe(startWith(null));
  }

  getPropertyNames(): string[] {
    return this._propertyNames;
  }

  sendRequest(): Observable<void> {
    return forkJoin([
      this._geojsonFeatures$.asObservable().pipe(take(1)),
      this._elementTypeId$.asObservable().pipe(take(1)),
    ]).pipe(
      switchMap(([geoJson, typeDefinition]) => {
        let url = `${this.apiUrl}/gis/import/${typeDefinition.gisLayerId}?networkElementTypeId=${typeDefinition.networkElementTypeId}`;

        if (typeDefinition.networkElementSubTypeId) {
          url = `${url}&networkElementSubTypeId=${typeDefinition.networkElementSubTypeId}`;
        }

        const blob = new Blob([JSON.stringify(geoJson)], { type: 'application/json' });

        const formData = new FormData();
        formData.append('file', blob, 'geojsonFeatures.json');

        return this.httpCacheClient.post<void>(url, formData);
      })
    );
  }

  setMappingsFormValid(isValid: boolean): void {
    this._isMappingsFormValid$.next(isValid);
  }

  resetAll(): void {
    this._elements$.next([]);
    this._gridSettings$.next(gridSettingBase);
    this._elementTypeId = null;
    this._elementTypeId$.next(this._elementTypeId);
    this._geojsonFeatures = null;
    this._geojsonFeatures$.next(this._geojsonFeatures);
    this._propertyNames = [];
    this._resetAll$.next();
  }

  private validate(geoJson: GeoJSON.GeoJSON): string[] {
    const jsonString = JSON.stringify(geoJson);
    const jsonSize = new Blob([jsonString]).size;

    if (jsonSize > this._settingsService.maxGeoJsonSize) {
      return ['GeoJSON size exceeds 700 MB'];
    }

    if (!geoJson) {
      return ['Invalid GeoJSON'];
    }

    if (geoJson?.type != 'FeatureCollection') {
      return ['GeoJSON is not a FeatureCollection'];
    }

    const types = [...new Set(geoJson?.features?.map((f) => f?.geometry?.type))];

    if (types.length == 0) {
      return ['GeoJSON contains no Features'];
    }

    if (
      types?.length > 2 ||
      (types?.length == 2 && !types[0].endsWith(types[1]) && !types[1].endsWith(types[0]))
    ) {
      return [`GeoJSON is not exclusive FeatureCollection: ${types.join(', ')}`];
    }

    return [];
  }

  private getTable(
    geoJson: GeoJSON.FeatureCollection,
    rows: number = 50000
  ): { [name: string]: any }[] {
    let properties = geoJson.features.filter((i, index) => index < rows).map((f) => f.properties);

    properties = properties.map((item) => {
      let newItem = {};

      for (let key in item) {
        let newKey = this._stringHelperService.replaceAll(key, '-', separator);
        const numericValue = Number(item[key]);
        newItem[newKey] = isNaN(numericValue) ? item[key] : numericValue;
      }

      return newItem;
    });

    return properties;
  }

  private getTableGridSettings(columns: TField[]): GridSetting {
    var gridSetting = globalUtilsHelper.clone(gridSettingBase, true);

    for (const column of columns) {
      gridSetting.gridColumnSettings.push({
        field: column.fieldNormalized,
        visible: true,
        title: column.title,
        width: 150,
        type: column.isNumeric ? 'numeric' : 'text',
        localeTitle: { 'en-GB': column.title },
      });
    }

    return gridSetting;
  }

  private getTableColumns(table: { [name: string]: any }[]): TField[] {
    const columns = [] as TField[];
    const columnNames = this.getColumnNames(table);

    for (const column of columnNames) {
      const field = this._stringHelperService.replaceAll(column, separator, '-');

      const isNumeric =
        table.every((x) => {
          const v = x[column];
          return v === undefined || v === null || typeof v == typeNumber || !isNaN(Number(v));
        }) && table.some((x) => !isNaN(Number(x[column])));

      columns.push({
        field: field,
        isNumeric: field === this._gisIdProperty ? false : isNumeric,
        fieldNormalized: column,
        title: field,
      });
    }

    return columns.sort((a, b) => a.title.localeCompare(b.title));
  }

  private getColumnNames(table: { [name: string]: any }[]): string[] {
    const nameSet = new Set<string>();

    for (const row of table) {
      for (const key in row) {
        if (row.hasOwnProperty(key)) {
          nameSet.add(key);
        }
      }
    }

    return Array.from(nameSet);
  }

  private async loadJson(file: File): Promise<GeoJSON.GeoJSON> {
    return await load(file[0], _GeoJSONLoader);
  }

  private readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  private async readShapefile(
    shpArrayBuffer: ArrayBuffer,
    dbfArrayBuffer: ArrayBuffer
  ): Promise<any> {
    const source = await shapefile.open(shpArrayBuffer, dbfArrayBuffer);
    const features = [];
    let result;

    while (!(result = await source.read()).done) {
      features.push(result.value);
    }

    return {
      type: 'FeatureCollection',
      features: features,
      shape: 'geojson-table',
    };
  }

  private async loadShapeFile(files: File[]): Promise<GeoJSON.GeoJSON> {
    const shpFile = files.find((file) => file.name.endsWith('.shp'));
    const dbfFile = files.find((file) => file.name.endsWith('.dbf'));
    const maxShapeFileSize = this._settingsService.maxShapeFileSize;
    const maxShapeFileSizeGB = (maxShapeFileSize / (1024 * 1024 * 1024)).toFixed(2);

    if (!shpFile || !dbfFile) {
      throw Error('.shp and .dbf files are required');
    }

    if (dbfFile.size > maxShapeFileSize) {
      throw Error('.dbf file size exceeds ' + maxShapeFileSizeGB + ' GB');
    }

    return Promise.all([
      this.readFileAsArrayBuffer(shpFile),
      this.readFileAsArrayBuffer(dbfFile),
    ]).then(([shpArrayBuffer, dbfArrayBuffer]) => {
      return this.readShapefile(shpArrayBuffer, dbfArrayBuffer);
    });
  }

  private calculatePropertyNames(): string[] {
    const propertyNames = new Set<string>();

    if (this._geojsonFeatures?.features) {
      this._geojsonFeatures.features.forEach((feature) => {
        const currentPropertyNames = Object.keys(feature.properties);
        currentPropertyNames.forEach((propertyName) => {
          propertyNames.add(propertyName);
        });
      });
    }
    return Array.from(propertyNames);
  }
}
