import { inject, Injectable, Injector } from '@angular/core';
import { GetCacheOpts } from '@common-modules/cache/http-cache/http-cache.client';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { PagedResultDto } from '@common-modules/shared/model/paged-result.dto';
import { GridODataService } from '@common-modules/shared/odata/grid-odata.service';
import { map, Observable } from 'rxjs';
import { HierarchyElementPressureSignalDto } from '../../shared/model/signals/hierarchy-element-pressure-signal.dto';
import { PressureSignalConfigurationDto } from '../../shared/model/signals/pressure-signal-configuration.dto';
import { PressureSignalDto } from '../../shared/model/signals/pressure-signal.dto';
import { OffsetUnitService } from '../ne-configuration-pressure-historical/offset-unit.service';

const endpointUrl = '/api/pressure';
@Injectable({
  providedIn: 'root',
})
export class NeConfigurationPressureService extends GridODataService<PressureSignalDto> {
  expiration: GetCacheOpts = { avoid: true };

  private readonly _offsetUnitService = inject(OffsetUnitService);

  constructor(injector: Injector) {
    super(injector, `${endpointUrl}/available`);
  }

  protected mapResponse(
    response: PagedResultDto<PressureSignalDto>
  ): PagedResultDto<PressureSignalDto> {
    return response;
  }

  getPressureConfigurationByZone(
    elementId: string
  ): Observable<HierarchyElementPressureSignalDto[]> {
    return this.httpCacheClient
      .get<HierarchyElementPressureSignalDto[]>(`${this.baseUrl}${endpointUrl}/${elementId}`, {
        avoid: true,
      })
      .pipe(
        map((results) => {
          results.forEach((result) => {
            result.offset = this._offsetUnitService.convertFromUom(result.offset);
          });
          return results;
        })
      );
  }

  savePressureConfiguration(
    pressureConfiguration: PressureSignalConfigurationDto
  ): Observable<boolean> {
    // Clone because we are modifying theoffset by reference
    const data = globalUtilsHelper.clone(pressureConfiguration, true);
    data.signals.forEach((signal) => {
      signal.offset = this._offsetUnitService.convertToUom(signal.offset);
    });

    return this.httpCacheClient.post(`${this.baseUrl}${endpointUrl}/save`, data);
  }
}
