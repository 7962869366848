export class WlmDialogSettings {
  // The key of the message to show
  translateKey: string;
  // Params to interpolate values in strings
  params?: any;
  // Key of the text to display in the confirm button
  confirmButtonTextKey?: string;
  // Key of the text to display in the deny button
  denyButtonTextKey?: string;
  // Key of the text to display in the cancel button
  cancelButtonTextKey?: string;
  icon?: 'success' | 'error' | 'warning' | 'info' | 'question';
  timer?: number;
  html?: any;
  showIcon?: boolean;

  constructor(init: Readonly<WlmDialogSettings>) {
    Object.assign(this, init);
  }
}
