import { Component, inject, Injector, ViewChild } from '@angular/core';
import { NetworkElementAttributeLinkDto } from '@common-modules/dependencies/ne/network-element-link-dto';
import { FilterGroupFieldSettings } from '@common-modules/dependencies/wlm-filters/filter-group-field-settings';
import { FilterGroupSettings } from '@common-modules/dependencies/wlm-filters/filter-group-settings';
import { ElementTypeFilter } from '@common-modules/dependencies/wlm-filters/i-filters/element-type-filter';
import { GenericCrudSettings } from '@common-modules/generic-crud/generic-crud-settings';
import { GenericCrudComponent } from '@common-modules/generic-crud/generic-crud/generic-crud.component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { DialogService } from '@common-modules/shared/dialogs/dialogs.service';
import { DataBindingFilters } from '@common-modules/shared/filters/component-filters/data-binding-filters';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';

import { NetworkElementAttributeTypeCrudDto } from '@common-modules/dependencies/ne/network-element-attribute-type-crud.dto';
import { ValidationHelperService } from '@common-modules/shared/helpers/validation-helper.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { finalize, map, Observable, of, switchMap, tap } from 'rxjs';
import { NEATypesCrudService } from '../../neat-types/neat-types-crud-service';
import { LinksCrudService } from '../links-crud-service';
import { NetNeatLinkCrudService } from '../net-neat-link-crud-service';
import { NetNeatLinkFormComponent } from '../net-neat-link-form/net-neat-link-form.component';

const COMPONENT_SELECTOR = 'wlm-net-neat-link-crud';
@Component({
  selector: 'wlm-net-neat-link-crud',
  templateUrl: './net-neat-link-crud.component.html',
  styleUrl: './net-neat-link-crud.component.scss',
})
export class NetNeatLinkCrudComponent {
  @ViewChild(GenericCrudComponent) genericCrudComponent: GenericCrudComponent;
  settings: GenericCrudSettings;
  isZone: boolean = false;
  selectedItem: NetworkElementAttributeLinkDto;
  items: NetworkElementAttributeLinkDto[];
  filterSettings: FilterGroupSettings;
  canLoadFilter = false;
  filters: DataBindingFilters;
  networkElementTypeId: number;
  setLoading: (isLoading: boolean) => void;
  filtersSetted = false;
  private _networkElementTypeIdFieldName = 'networkElementTypeId';

  private readonly _injector = inject(Injector);
  private readonly _dialogService = inject(DialogService);
  private readonly _netNeatLinkCrudService = inject(NetNeatLinkCrudService);
  private readonly _validationHelperService = inject(ValidationHelperService);
  private readonly _spinnerService = inject(SpinnerService);
  private readonly _neaTypesCrudService = inject(NEATypesCrudService);

  readonly T_SCOPE = `${AppModules.Integration}.${COMPONENT_SELECTOR}`;

  constructor(private readonly _linksCrudService: LinksCrudService) {
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {
    this.buildSettings();
    this.setFilterSettings();
  }

  buildSettings(): void {
    this.settings = new GenericCrudSettings({
      injector: this._injector,
      service: 'NetNeatLinkCrudService',
      grid: {
        gridSettingsName: 'NetworkElementAttributesTypesLinks',
      },
      create: {
        formComponent: NetNeatLinkFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      update: {
        formComponent: NetNeatLinkFormComponent,
        beforeSaveHook: this.buildBeforeSaveHook(true),
      },
      delete: {
        disableHook: this.disableHook,
      },
    });
  }

  onDeleteCustomConfirm(): void {
    const confirmMessageKey = this.getConfirmDeleteMessageKey();
    this._dialogService
      .showTranslatedDialogMessage({
        translateKey: confirmMessageKey,
        icon: 'warning',
        showIcon: true,
      })
      .pipe(
        switchMap((result) => {
          if (result.result) {
            this.setLoading(true);
            return this._netNeatLinkCrudService
              .deleteCustomConfiguration(this.networkElementTypeId)
              .pipe(
                tap(() => {
                  this._dialogService.showEntityActionSnackBar('delete', 'configuration');
                  this.genericCrudComponent?.refreshItems();
                })
              );
          } else {
            return of(null);
          }
        }),
        finalize(() => this.setLoading(false))
      )
      .subscribe({
        error: this._dialogService.showErrorMessage,
      });
  }

  private getConfirmDeleteMessageKey(): string {
    return 'common.messages.delete-confirm-generic-custom';
  }

  private setFilterSettings() {
    const fields: { [field: string]: FilterGroupFieldSettings } = {
      networkElementType: new FilterGroupFieldSettings({
        fieldName: this._networkElementTypeIdFieldName,
      }),
    };

    this.filterSettings = new FilterGroupSettings({
      fields,
      persistencyArea: globalUtilsHelper.generateGuid(),
    });
  }

  private getNetworkElementTypeId(): number {
    if (!this.filters) {
      return null;
    }
    const filter = this.filters.filters.get(
      this._networkElementTypeIdFieldName
    ) as ElementTypeFilter;
    const selection = filter?.selectedElements;
    const id = selection?.length ? selection[0] : selection;
    this.networkElementTypeId = id;
    this._linksCrudService.setNetworkElementTypeId(id);
    return id;
  }

  setDataBindingFilters(filtersParameters: DataBindingFilters): void {
    this.canLoadFilter = filtersParameters.exportableFilter !== undefined;
    this.filters = filtersParameters;
  }

  applyFilters(): void {
    if (this.canLoadFilter) {
      const networkElementTypeId = this.getNetworkElementTypeId();
      this.genericCrudComponent.applyExternalFilters({
        networkElementTypeId,
      });
      this.filtersSetted = true;
    }
  }

  onClearAll(): void {
    this.filters = null;
    this.genericCrudComponent.applyExternalFilters(null);
    this.filtersSetted = false;
  }

  private disableHook = (model: NetworkElementAttributeTypeCrudDto): Observable<boolean> => {
    const isCustom = this._neaTypesCrudService.isCustomAttributeFalse(model);
    return of(isCustom);
  };

  private buildBeforeSaveHook =
    (isCreate: boolean) =>
    (
      model: NetworkElementAttributeTypeCrudDto
    ): Observable<NetworkElementAttributeTypeCrudDto | null> => {
      return this._netNeatLinkCrudService.validate(model).pipe(
        map((isValid) => {
          if (!isValid) {
            this._validationHelperService.showNotUniqueError(
              `${this.T_SCOPE}.properties.gis-layer-kpi-property`
            );
            return null;
          }
          return model;
        })
      );
    };
}
