import { inject, Injectable, Injector } from '@angular/core';
import { ReadonlyNetworkElementTypes } from '@common-modules/dependencies/he/hierarchy.constants';
import { INetworkElementShapeDto } from '@common-modules/dependencies/ne/network-element-shape.dto';
import { INetworkElementTypeDto } from '@common-modules/dependencies/ne/network-element-type.dto';
import { NetworkShapeTypes } from '@common-modules/dependencies/ne/network-shape-types';
import { GenericCrudService } from '@common-modules/generic-crud/generic-crud.service';
import { BaseService } from '@common-modules/shared/base.service';
import { SharedConstantsService } from '@common-modules/shared/constants/shared-constants.service';
import { globalUtilsHelper } from '@common-modules/shared/helpers/global-utils-helper';
import { SelectOption } from '@common-modules/shared/model/shared/select-option';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { map, Observable } from 'rxjs';
import { INetworkElementTypeValidationDto } from './network-element-type-validation.dto';

@Injectable()
export class NETypesCrudService
  extends BaseService
  implements GenericCrudService<INetworkElementTypeDto, number>
{
  private _entityUrl: string;

  private readonly _sharedConstants = inject(SharedConstantsService);

  constructor(injector: Injector) {
    super(injector);
    this._entityUrl = `${this.apiUrl}/integration/network-element-types`;
  }

  getAll(): Observable<INetworkElementTypeDto[]> {
    return this.httpCacheClient.get<INetworkElementTypeDto[]>(this._entityUrl).pipe(
      map((entities) => {
        entities.forEach((entity) => this.pathToShape(entity));
        return entities;
      })
    );
  }

  create(entity: INetworkElementTypeDto): Observable<INetworkElementTypeDto> {
    this.shapeToPath(entity);
    return this.httpCacheClient.post<INetworkElementTypeDto>(this._entityUrl, entity);
  }

  update(entity: INetworkElementTypeDto): Observable<INetworkElementTypeDto> {
    this.shapeToPath(entity);
    return this.httpCacheClient.put<INetworkElementTypeDto>(this._entityUrl, entity);
  }

  delete(id: number): Observable<boolean> {
    return this.httpCacheClient.delete(`${this._entityUrl}/${id}`).pipe(map(() => true));
  }

  getId(entity: INetworkElementTypeDto): number {
    return entity.networkElementTypeId;
  }

  isReadonlyType = (networkyElementTypeId: number) =>
    !!(
      networkyElementTypeId &&
      ReadonlyNetworkElementTypes.find((type) => type === networkyElementTypeId)
    );
  validate(
    entity: INetworkElementTypeDto,
    isCreate?: boolean
  ): Observable<INetworkElementTypeValidationDto> {
    return this.httpCacheClient.post<INetworkElementTypeValidationDto>(
      `${this._entityUrl}/validate?isCreate=${isCreate ? 'true' : 'false'}`,
      entity
    );
  }

  pathToShape(model: INetworkElementShapeDto): void {
    if (NetworkShapeTypes.LINE !== model.iconPath && NetworkShapeTypes.POLYGON !== model.iconPath) {
      model.shapeType = NetworkShapeTypes.ICON;
    } else {
      model.shapeType = model.iconPath;
    }
  }

  shapeToPath(model: INetworkElementShapeDto): void {
    if (model.shapeType !== NetworkShapeTypes.ICON) {
      model.iconPath = model.shapeType;
    }
  }

  private isShapeType = (field: FormlyFieldConfig, value: NetworkShapeTypes) =>
    field.form.controls['shapeType'].value === value;

  private getNetworkShapeTypes$ = (): Observable<SelectOption<string>[]> =>
    this._sharedConstants
      .getNetworkShapeTypeMapping()
      .pipe(map((value) => globalUtilsHelper.hashToSelectOptions(value)));

  buildShapeSelectorFieldConfig(): FormlyFieldConfig[] {
    return [
      {
        key: 'shapeType',
        type: 'select',
        className: 'col-12',
        defaultValue: NetworkShapeTypes.ICON,
        props: {
          label: 'Shape Type',
          valueProp: 'value',
          labelProp: 'label',
          required: true,
          options: this.getNetworkShapeTypes$(),
        },
      },
      {
        key: 'iconPath',
        type: 'svg-editor',
        className: 'col-12',
        props: {
          label: 'Icon',
          previewLabel: 'Icon Preview',
          previewPlaceholder: 'Introduce valid SVG content',
        },
        validators: {
          validation: [{ name: 'notSvg' }],
        },
        expressions: {
          hide: (field) => !this.isShapeType(field, NetworkShapeTypes.ICON),
        },
      },
      {
        key: 'iconColor',
        type: 'color-picker',
        className: 'col-6',
        props: {
          label: 'Map Color',
        },
        expressions: {
          hide: (field) => this.isShapeType(field, NetworkShapeTypes.ICON),
        },
      },
      {
        key: 'iconStrokeColor',
        type: 'color-picker',
        className: 'col-6',
        props: {
          label: 'Map Stroke Color',
        },
        expressions: {
          hide: (field) => this.isShapeType(field, NetworkShapeTypes.ICON),
        },
      },
    ];
  }
}
