export class FilterItemSelectOption {
  params?: { [key: string]: any };
  constructor(
    public value: any,
    public label: string,
    public icon?: string,
    public width: number = 20,
    public height: number = 20,
    public isAdditional?: boolean
  ) {}
}
