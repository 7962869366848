const https = location.protocol === 'https';

export const environment = {
  production: false, // Cannot be refactored.
  version: '5.3.41212.1212-dev',
  copyright: '#{uxCopyright}#',
  legalUrl: '#{uxLegalUrl}#',
  releaseDate: '#{uxReleaseDate}#',
  log: false,
  rxjsLog: false,
  application: {
    name: 'wlmux',
    logoUrl: '',
    companyLogo: '',
  },
  oAuthConfig: {
    get issuer(): string {
      return location.protocol === 'https' ? '#{identityUrlHttps}#' : '#{identityUrl}#';
    },
    clientId: 'WLMUX_App',
    dummyClientSecret: '1q2w3e*',
    scope: 'openid profile email WLMUX',
    showDebugInformation: true,
    oidc: true,
    requireHttps: false,
    response_type: 'id_token token',
    get redirectUri(): string {
      return location.protocol === 'https' ? '#{redirectUrlHttps}#' : '#{redirectUrl}#';
    },
  },
  apis: {
    default: {
      get url(): string {
        return location.protocol === 'https' ? '#{apiUrlHttps}#' : '#{apiUrl}#';
      },
    },
    interfaces: {
      get url(): string {
        return location.protocol === 'https' ? '#{interfacesUrlHttps}#' : '#{interfacesUrl}#';
      },
    },
    config: {
      get url(): string {
        return location.protocol === 'https'
          ? '#{apiUrlHttps}#/api/settings/frontend'
          : '#{apiUrl}#/api/settings/frontend';
      },
    },
  },
  localization: {
    defaultResourceName: 'wlmux',
    forcedDateFormat: undefined,
    forcedHourFormat: undefined,
    defaultLocale: 'en-GB',
    availableLocales: ['es', 'en-GB', 'tr', 'it', 'zh-CN'],
  },
  gis: {
    glUrl: '#{glUrl}#',
    raster: {
      tiles: ['#{rasterUrl1}#', '#{rasterUrl2}#', '#{rasterUrl3}#'],
      tilesSet: null,
      size: 256,
    },
    map: {
      center: ['#{mapCenterLat}#', '#{mapCenterLon}#'],
      zoom: 10,
    },
    coordinatesSystem: 'latLong',
  },
  headers: {
    spinnerHeader: 'X-Loading-Spinner',
  },
  defaultCacheDurationMinutes: 1440,
  endDateLowerEqual: false,
  increaseEndDate: true,
  minAllowedYear: 1700,
  uomConversionDecimals: 15,
  uomMinPositionsToKeepPrecision: 6,
  chartDisplaySymbolThreshold: 3000,
  maxDecimalPositions: 4,
  maxDecimalPositionsForGreatNumber: 2,
  maxOverflowToShowExtendedTooltip: 200,
  maxGeoJsonSize: 700 * 1024 * 1024,
  maxShapeFileSize: 2.5 * 1024 * 1024 * 1024,
  cards: {
    telemetry: {
      titleField: 'description',
      subtitleField: 'id',
    },
    hierarchy: {
      titleField: 'name',
      subtitleField: 'id',
    },
  },
  tree: {
    hierarchy: {
      titleField: 'name',
      subtitleField: 'id',
    },
  },
};
