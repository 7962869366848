import { INeHeFilterSettings } from '@common-modules/common-filters/filters/ne-type-filter/nehe-filter-settings';

export class NeTypeFilterConfiguration {
  persistencyArea: string;
  neSettings: INeHeFilterSettings;
  defaultNETypeIds: any[];
  neSubTypesIds?: any[];

  constructor(init: NeTypeFilterConfiguration) {
    Object.assign(this, init);
  }
}
