import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DropdownNavigationItem } from '@common-modules/dependencies/navigation/dropdown-navigation-item';
import { TabDetailPanelParameters } from '@common-modules/dependencies/navigation/tab-detail-component';
import { AppModules } from '@common-modules/shared/app-modules.enum';
import { BasePageComponent } from '@common-modules/shared/component/base-page.component';
import { LocalStorageService } from '@common-modules/shared/local-storage.service';
import { SpinnerService } from '@common-modules/wlm-spinner/spinner.service';
import { finalize } from 'rxjs';
import { HomePageService } from '../../../shared/services/home-page.service';
import { IntegrationWizardSteps } from './integration-wizard-steps';

const COMPONENT_SELECTOR = 'wlm-integration-page';

@Component({
  selector: COMPONENT_SELECTOR,
  templateUrl: './integration-page.component.html',
  styleUrls: ['./integration-page.component.scss'],
})
export class IntegrationPageComponent extends BasePageComponent implements OnInit {
  currentStep: number = 0;
  stepControls: { [stepId: number]: FormGroup };
  stepPositions: { [stepId: number]: number };
  stepsCompleted: { [stepId: number]: boolean } = {};
  readonly tooltipShowDelay = 1000;

  readonly stepsPositionArray = [
    IntegrationWizardSteps.AppAttributes,
    IntegrationWizardSteps.NetworkElementTypes,
    IntegrationWizardSteps.NetworkElementSubTypes,
    IntegrationWizardSteps.NetworkElementAttributeTypes,
    IntegrationWizardSteps.NetNeatLink,
    IntegrationWizardSteps.HierarchyElementTypes,
    IntegrationWizardSteps.MaterialTypes,
    IntegrationWizardSteps.CustomerClassTypes,
    IntegrationWizardSteps.BillingClasses,
    IntegrationWizardSteps.ActivityTypes,
    IntegrationWizardSteps.SapCodeGroups,
    IntegrationWizardSteps.SapCodes,
    IntegrationWizardSteps.LeakTypeGroups,
    IntegrationWizardSteps.LeakTypes,
    IntegrationWizardSteps.SapLeakCodes,
    IntegrationWizardSteps.FamiliesAndRelations,
    IntegrationWizardSteps.GisLayers,
    IntegrationWizardSteps.GisLayerKpi,
  ];

  readonly stepTranslations = {
    [IntegrationWizardSteps.AppAttributes]: 'app-attributes',
    [IntegrationWizardSteps.NetworkElementTypes]: 'network-element-types',
    [IntegrationWizardSteps.NetworkElementSubTypes]: 'network-element-subtypes',
    [IntegrationWizardSteps.NetworkElementAttributeTypes]: 'network-element-attribute-types',
    [IntegrationWizardSteps.HierarchyElementTypes]: 'hierarchy-element-types',
    [IntegrationWizardSteps.MaterialTypes]: 'material-types',
    [IntegrationWizardSteps.CustomerClassTypes]: 'customer-class-types',
    [IntegrationWizardSteps.BillingClasses]: 'billing-classes',
    [IntegrationWizardSteps.ActivityTypes]: 'activity-types',
    [IntegrationWizardSteps.SapCodeGroups]: 'sap-code-groups',
    [IntegrationWizardSteps.SapCodes]: 'sap-codes',
    [IntegrationWizardSteps.LeakTypeGroups]: 'leak-type-groups',
    [IntegrationWizardSteps.LeakTypes]: 'leak-types',
    [IntegrationWizardSteps.SapLeakCodes]: 'sap-leak-codes',
    [IntegrationWizardSteps.FamiliesAndRelations]: 'families-and-relations',
    [IntegrationWizardSteps.GisLayers]: 'gis-layers',
    [IntegrationWizardSteps.NetNeatLink]: 'net-neat-link',
    [IntegrationWizardSteps.GisLayerKpi]: 'gis-layer-kpi',
  };

  readonly steps = IntegrationWizardSteps;
  readonly T_SCOPE = `${AppModules.Integration}.page`;
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _homePageService = inject(HomePageService);
  private readonly _spinnerService = inject(SpinnerService);
  private readonly _router = inject(Router);
  private readonly _route: ActivatedRoute = inject(ActivatedRoute);
  private readonly _destroyRef: DestroyRef = inject(DestroyRef);
  private readonly _stepPersistencyKey = 'wl-integration-step';
  private readonly _stepNavigationKey = 'initialStep';
  private setLoading: (loading: boolean) => void;

  constructor() {
    super();
    this.hasRightPanel = false;
    this.buildStepsPositionHash();
    this.buildStepControls();
    this.setLoading = this._spinnerService.buildSetLoadingFn();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.currentStep = this.loadCurrentStep();
    this.listenQueryParams();
  }

  onStepChange(event: StepperSelectionEvent): void {
    this.currentStep = event.selectedIndex;
    this.persistCurrentStep();
  }

  onCompleteIntegration(): void {
    this.setLoading(true);
    this._homePageService
      .changeToDefaultPage()
      .pipe(finalize(() => this.setLoading(false)))
      .subscribe(() => {
        this._router.navigate(['/home']);
      });
  }

  private listenQueryParams(): void {
    this._route.queryParams
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((queryParams: Params) => {
        const stepId: IntegrationWizardSteps = queryParams[this._stepNavigationKey];

        if (typeof stepId !== 'undefined') {
          this.currentStep = this.stepPositions[stepId];
        }
      });
  }

  private buildStepsPositionHash(): void {
    this.stepPositions = this.stepsPositionArray.reduce((accum, stepId, position) => {
      accum[stepId] = position;
      return accum;
    }, {});
  }

  private buildStepControls(): void {
    this.stepControls = this.stepsPositionArray.reduce((accum, stepId) => {
      accum[stepId] = new FormGroup({});
      return accum;
    }, {});
  }

  get titleTranslationKey(): string {
    return `${this.T_SCOPE}.title`;
  }

  get persistencyArea(): string {
    return this.pageCrud;
  }

  get pageCrud(): string {
    return 'IntegrationCrud';
  }

  get navigations(): DropdownNavigationItem[] {
    return [];
  }

  mapInitParameters(parameters: TabDetailPanelParameters) {}

  init(): void {}

  private persistCurrentStep(): void {
    this._localStorageService.addOrUpdate(this._stepPersistencyKey, this.currentStep);
  }

  private loadCurrentStep(): number {
    return this._localStorageService.get(this._stepPersistencyKey, 0);
  }
}
